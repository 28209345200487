import React, { PureComponent } from 'react';
import { Button, message } from 'antd';
import { config as formConfig, report } from "../config/forms/v1"
import { Form, Reports } from "../config/forms/formTypes"
import { Typography, Radio, Checkbox } from 'antd';
import { LanguageIndex } from "../config/language/index"
import { lang, getCurrentLanguage } from '../function/lang';
import { config, codeTree } from '../config/forms/v1';
//@ts-ignore
import * as convertBase from 'bigint-base-converter';
import { EXEC, EXECString } from '../function/exec';

const { Title, Paragraph, Text, Link } = Typography;
const switchResult = false
//情况四的开关

type ReportType = "RESULT_1" | "RESULT_2" | "RESULT_3" | "RESULT_4"

type State = {
  code: string
}

interface ReportInterface {
  form: { [str: string]: number }
  display: boolean
  onClose: () => void
  ReCAPTCHAKey?: string
}

const 六十二进制 = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
export class Report extends PureComponent<ReportInterface, State> {
  constructor(props: ReportInterface) {
    super(props);
    this.state = {
      code: "..."
    }
  }

  getValue(inputTag: string) {
    const formDataInput = this.props.form
    for (let inputItem in formDataInput) {
      if (inputItem === inputTag) {
        return formDataInput[inputItem]
      }
    }
    return -1
  }
  calculate(): ReportType {
    // const reportData = calculatorReportInput.data
    const reportData = report.data

    console.log("calculate", "start", reportData)

    let maxCall = 1000
    let currentTag = ""
    let done = false
    while (maxCall > 0 && !done) {
      maxCall--
      for (let checks of reportData) {
        if (checks.tag === currentTag || currentTag === "") {
          console.log("calculate", "currentTag", checks.tag)
          const rawValue = this.getValue(checks.inputTag)
          let value: number

          if (checks.timesTag) {
            const othValue = this.getValue(checks.timesTag)
            value = rawValue * othValue
            console.warn("!", "timesTag", {
              checks: checks,
              timesTag: checks.timesTag,
              othValue,
              rawValue,
              value,
            })

          } else {
            value = rawValue
          }
          if (checks.multiple) {
            let perValue = value
            value = value * checks.multiple
            console.warn("!", "multiple", {
              checks: checks,
              multiple: checks.multiple,
              perValue,
              value,
            })
          }

          console.log("calculate", "getValue", {
            rawValue,
            inputTag: checks.inputTag,
            tag: checks.tag,
            reportData
          }, value, checks.options)

          let nextTag = ""
          for (let option of checks.options) {
            if (value >= option.value) {
              nextTag = option.goto
            }
          }

          console.log("calculate", "nextTag", nextTag)

          switch (nextTag) {
            case "RESULT_1":
            case "RESULT_2":
              done = true
              if (switchResult) {
                return "RESULT_4"
              }
              return nextTag
            case "RESULT_3":
              done = true
              return nextTag

            default:
              break;
          }
          currentTag = nextTag
          break
        }
      }
    }
    throw "CANNOT RESOLVE"
  }


  sendReport(idCode: string, ReCAPTCHAKey: string) {
    const res = this.calculate()
    let Rcode: 1 | 2 | 3 = 1
    switch (res) {
      case "RESULT_1":
        Rcode = 1
        break;
      case "RESULT_2":
        Rcode = 2
        break;
      case "RESULT_3":
        Rcode = 3
        break;
      default:
        break;
    }

    const printUrl = this.getPrintUrl(Rcode)


    const PROD_prefix = `https://pwi2aamxjb.execute-api.ap-east-1.amazonaws.com/default/PROD-form_livingwithlungcancer_asia`
    const TEST_prefix = `https://e1zjq2jaz8.execute-api.ap-east-1.amazonaws.com/default/DEV_form_livingwithlungcancer_asia`

    const isPROD = document.location.hostname === "screentest.livingwithlungcancer.asia"
    const isPROD2 = document.location.hostname === "form.livingwithlungcancer.asia"

    const prefix = isPROD || isPROD2 ? PROD_prefix : TEST_prefix

    const url = `${prefix}?type=${Rcode}&url=${encodeURIComponent(printUrl)}&code=${idCode}&ReCAPTCHAKey=${ReCAPTCHAKey}`
    if (document.location.port) {
      console.log("debug", url, "skip")
    } else {
      console.log("send", url)
      fetch(url)
    }


  }
  componentDidMount() {
    console.log("REPORT", this.props.form)
    const code = this.calcCode()
    this.setState({
      code
    })
    if (localStorage.getItem("lastCode") === code) {
      return
    } else {
      localStorage.setItem("lastCode", code)
    }
    if (this.props.ReCAPTCHAKey) {
      this.sendReport(code, this.props.ReCAPTCHAKey)
    } else {
      message.error(`ReCAPTCHA failed`)
    }

  }
  三进制To六十二进制(input: string) {
    const convertResult: number[] = convertBase(input, 3, 64)
    const outArr = []
    for (let number of convertResult) {
      outArr.push(六十二进制[number])
    }
    return outArr.join("")
  }
  六十二进制To三进制(input: string) {
    const inputArr = input.split("")
    const preArr = []
    for (let item of inputArr) {
      preArr.push(六十二进制.indexOf(item))
    }
    console.log("preArr", preArr)
    const dec = convertBase(preArr, 64, 3)
    return dec.join("")
  }

  calcCode() {
    const form = this.props.form
    console.log("calcCode", form)
    const table = codeTree.data
    const tempArr = []
    const tempKeyArr = []
    let tempNumberString = ""
    for (let item of table) {
      const key = item.tag
      const RAW_VALUE = form[key]
      let thenValue = RAW_VALUE
      if (item.times) {
        thenValue = thenValue * item.times
      }
      //2分割，01二进制

      tempKeyArr.push(key)
      switch (item.method) {
        case "Binary":
          if (thenValue === 0 || thenValue === 1) {
            tempNumberString += `${thenValue}`
          }
          break;
        case "Decimal":
          if (thenValue >= 0) {
            tempNumberString += `${(thenValue).toString(2)}`
          }
          break;
        default:
          break;
      }

      tempNumberString += `${2}`
    }

    console.log("tempNumberString", tempNumberString)

    let inputv = tempNumberString.split('.')[0] += `${22222}`
    console.log("tempNumberString", "inputv", inputv)
    const dec = this.三进制To六十二进制(inputv)
    return dec
  }


  getPrintUrl(type: 1 | 2 | 3 | 4) {
    const formRaw = this.props.form
    const form = { ...formRaw, time: new Date().toISOString() }
    const origin = document.location.origin
    const formText = encodeURIComponent(JSON.stringify(form))
    const code = this.calcCode()
    const url = `${origin}/print/${type}/${code}/${formText}/#${getCurrentLanguage()}`
    console.log("print", url)
    return url
  }
  print(type: 1 | 2 | 3 | 4) {
    if (type === 1 || type === 2) {
      type = 4
    }
    const url = this.getPrintUrl(type)
    console.log("opening", url)
    window.open(url)
    // EXECString(`window.open('${url}')`)
  }

  render() {
    let resultCode: 1 | 2 | 3 | 4 = 1
    var result = <span>{"不適用"}</span>
    var resultParagraph: JSX.Element[] = []


    switch (this.calculate()) {
      case "RESULT_3":
        // 低风险
        resultCode = 3
        result = <span style={{ color: "rgb(0, 137, 146)" }}>{lang("较低")}</span>
        resultParagraph = [
          <Paragraph>{lang("结果无风险段落_1")}</Paragraph>,
          // <Paragraph>{this.state.code}</Paragraph>,

        ]
        break;

      case "RESULT_1":
      //   // 高风险且免费
      // resultCode = 1
      //   result = <span style={{ color: "rgb(0, 137, 146)" }}>{lang("较高")}</span>
      //   resultParagraph = [
      //     <Paragraph>{lang("结果免费段落_1")}</Paragraph>,
      //     // <Paragraph>{this.state.code}</Paragraph>,
      //     <Paragraph>{lang("结果免费段落_2_前")}
      //       <Link href="/docs/spec/proximity-cn">
      //         <span style={{ color: "rgb(0, 137, 146)" }}>
      //           {lang("结果免费段落_2_中")}
      //         </span>
      //       </Link>
      //       {lang("结果免费段落_2_后")}
      //     </Paragraph>,
      //   ]
      // // break;
      case "RESULT_2":
      //   // 高风险且需付费
      // resultCode = 2
      //   result = <span style={{ color: "rgb(0, 137, 146)" }}>{lang("较高")}</span>
      //   resultParagraph = [
      //     <Paragraph>{lang("结果付费段落_1")}</Paragraph>,
      //     // <Paragraph>{this.state.code}</Paragraph>,

      //     <Paragraph>{lang("结果付费段落_2")}</Paragraph>,
      //   ]
      // // break;

      case "RESULT_4":
        // 高风险
        resultCode = 4
        result = <span style={{ color: "rgb(0, 137, 146)" }}>{lang("较低")}</span>
        resultParagraph = [
          <Paragraph>{lang("结果无风险段落_1")}</Paragraph>,
          // <Paragraph>{this.state.code}</Paragraph>,

        ]
        break;
    }
    return (
      <div style={{ display: this.props.display ? undefined : "none", padding: 24 }}>


        <Title level={3} style={{ textAlign: "center" }}>
          {lang("感谢参与")}
        </Title>
        {/* <div style={{
          height: `calc(100% - ${33}px - ${140}px - ${70}px)`, minHeight: 180,
        }}>
          <Title level={3} style={{ textAlign: "center" }}>
            {lang("你患上肺癌的风险")}
            {result}
          </Title>
          {resultParagraph}
        </div> */}
        {(() => {
          if (false && resultCode === 1) {
            return <>
              <Paragraph>{lang("结果无风险段落_1")}</Paragraph>
              <Paragraph>{lang("报告段落_2_免费")}<b>{lang("报告段落_3_前_免费")} <a style={{ color: "#0000EE" }} href="http://wa.me/85264685836">{lang("报告段落_3_电话_免费")}</a> {lang("报告段落_3_前_免费后")} </b>{lang("报告段落_3_后_免费")}</Paragraph>
              <Paragraph>{lang("免费名额有限")}</Paragraph>
            </>
            // } else if (resultCode === 2) {
            //   return <>
            //     <Paragraph>{lang("符合不免费")}</Paragraph>
            //     <Paragraph>{lang("不包含")}<b>{lang("免费")}</b>{lang("不包含后")}</Paragraph>
            //     <Paragraph>{lang("分类1")}</Paragraph>
            //     <Paragraph>{lang("分类2")}</Paragraph>
            //     <Paragraph>{lang("分类3")}</Paragraph>
            //     <Paragraph>{lang("分类4")}</Paragraph>
            //     <Paragraph>{lang("34类")}</Paragraph>
            //   </>
          } else if (resultCode === 3) {
            return <>
              <Paragraph>{lang("不符合条件")}<b>{lang("免费")}</b>{lang("不符合条件后")}</Paragraph>
              <Paragraph>{lang("风险人士")}<sup>{lang("风险人士后-上标")}</sup>{lang("风险人士后-冒号")}</Paragraph>
              <Paragraph><li>{lang("年龄介乎")}</li></Paragraph>
              <Paragraph><li>{lang("小于15年")}</li></Paragraph>
              <Paragraph><li>{lang("大于15年")}</li></Paragraph>
              <Paragraph><li>{lang("没有吸烟习惯")}</li></Paragraph>
              <Paragraph>{lang("风险可变")}</Paragraph>
              <Paragraph>{lang("定期检查")}<sup>{lang("上标45")}</sup>{lang("定期检查后")}</Paragraph>
              <Paragraph>{lang("健康饮食")}<sup>{lang("上标4")}</sup></Paragraph>


            </>

          } else if (resultCode === 4) {
            return <>
              <Paragraph>{lang("免费配额用尽")}</Paragraph>
              <Paragraph>{lang("名额已满前")}<b>{lang("免费")}</b>{lang("名额已满后")}</Paragraph>
            </>
          }
        })()}



        <div style={{
          height: 70, textAlign: "center", justifyContent: "center", alignItems: "center",
        }}>
          <Button onClick={() => { this.print(resultCode) }} style={{ margin: 10 }}>
            {lang("下载评估结果")}
          </Button>
        </div>
        <div style={{}}>
          <Paragraph>{lang("报告页脚信息1")}</Paragraph>
          <Paragraph>{lang("报告页脚信息2")}</Paragraph>
          <Paragraph>
            {lang("报告页脚信息3_前")}
            <Link>{lang("报告页脚信息3_邮箱")}</Link>
            {lang("报告页脚信息3_后")}
          </Paragraph>
          <Paragraph>
            {lang("报告页脚信息4")}
          </Paragraph>

        </div>
        {(() => {
          if (resultCode === 3) {
            return <>
              <Paragraph>{lang("参考资料")}</Paragraph>
              <Paragraph>{lang("参考资料1")}</Paragraph>
              <Paragraph>{lang("参考资料2")}</Paragraph>
              <Paragraph>{lang("参考资料3")}</Paragraph>
              <Paragraph>{lang("参考资料4")}</Paragraph>
              <Paragraph>{lang("参考资料5")}</Paragraph></>
          }
        })()}
      </div>
    );

  }
}

