import React, { PureComponent } from 'react';
import { Button, message } from 'antd';
import { Typography, Radio, Checkbox } from 'antd';
import { LanguageIndex } from "../config/language/index"
import { lang } from '../function/lang';
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha"


const { Title, Paragraph, Text, Link } = Typography;

const ReCAPTCHAKey = "6LeN23geAAAAAPLCzaCfdl5ixVtTGz9tDyMAuI9N";
const recaptchaRef: React.RefObject<ReCAPTCHA> = React.createRef();

type State = {
  counter: number
  checked: boolean
  validating: boolean
}

interface BeforePostInterface {
  display: boolean
  onCancel: () => void
  onClose: (ReCAPTCHAKey: string) => void
}


export class BeforePost extends PureComponent<BeforePostInterface, State> {
  constructor(props: BeforePostInterface) {
    super(props);
    this.state = {
      counter: 0,
      checked: false,
      validating: false
    }
  }
  validate() {
    this.setState({
      validating: true
    })
    const recaptchaId = 0;
    const REF = recaptchaRef.current;
    console.log("ReCAPTCHA id", recaptchaId);
    console.log("recaptchaRef", REF);
    //@ts-ignore
    const grecaptcha: {
      execute: (input: any) => {},
      getResponse: () => {},
      ready: () => {},
      render: () => {},
      reset: () => {},
    } | undefined = REF?.props?.grecaptcha

    grecaptcha?.execute(recaptchaId);
    setTimeout(() => {
      this.setState({
        validating: false
      })
    }, 8000);
  };
  sendOrder(ReCAPTCHAKey: string | null) {
    console.log("sendOrder", "ReCAPTCHAKey", ReCAPTCHAKey, typeof (ReCAPTCHAKey))
    if (typeof (ReCAPTCHAKey) === "string") {
      this.props.onClose(ReCAPTCHAKey)
    } else {
      message.error("recaptcha error")

      this.setState({
        validating: false
      })
    }
  };
  render() {
    //@ts-ignore
    const aa = <ReCAPTCHA
      // ref={(ref) => (this.recaptcha = ref)}
      ref={recaptchaRef}
      size="invisible"
      sitekey={ReCAPTCHAKey}
      onChange={this.sendOrder.bind(this)}
    />

    return (
      <div style={{ display: this.props.display ? undefined : "none", padding: 24 }}>
        {aa}

        <Title level={3} style={{ textAlign: "center" }}>
          {/* {lang(this.partsTitle[item.part].title)} */}
          {this.state.counter === 0 ? lang("重要事项") : lang("個人資料私隱政策聲明")}
        </Title>
        <div style={{
          height: `calc(100% - ${33}px - ${140}px - ${70}px)`, minHeight: 240,
          display: this.state.counter === 0 ? undefined : "none"
        }}>
          <Paragraph>{lang(`重要事项段落_${1}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${2}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${3}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${4}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${5}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${6}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${7}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${8}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${9}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${10}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${11}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${12}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${13}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${14}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${15}`)}</Paragraph>
          <Paragraph>{lang(`重要事项段落_${16}`)}</Paragraph>
        </div>
        <div style={{
          height: `calc(100% - ${33}px - ${140}px - ${70}px)`, minHeight: 240,
          display: this.state.counter === 0 ? "none" : undefined
        }}>
          <Paragraph>{lang(`隐私段落_${1}`)}</Paragraph>
          <Paragraph>{lang(`隐私段落_${2}`)}</Paragraph>
          <Paragraph>
            {lang(`隐私段落_${3}_${"前"}`)}
            <Link>{lang(`隐私段落_${3}_${"邮箱"}`)}</Link>
            {lang(`隐私段落_${3}_${"后"}`)}
          </Paragraph>

          <Paragraph>
            {lang(`隐私段落_${4}_${"前"}`)}
            <Link>{lang(`隐私段落_${4}_${"邮箱"}`)}</Link>
            {lang(`隐私段落_${4}_${"后"}`)}
          </Paragraph>


          <Checkbox
            checked={this.state.checked}

            onChange={(event) => { this.setState({ checked: event.target.checked }) }}>
            <div>
              {lang("隐私复选框_1")}
            </div>
            <div>
              {lang("隐私复选框_2")}
            </div>
          </Checkbox>

        </div>


        <div style={{
          height: 70, textAlign: "center", justifyContent: "center", alignItems: "center",
          display: this.state.counter === 0 ? "flex" : "none"
        }}>
          <Button onClick={this.props.onCancel.bind(this)}
            style={{ margin: 10 }}>
            {lang("上一步")}
          </Button>
          <Button
            style={{ margin: 10 }}
            onClick={() => { this.setState({ counter: 1 }) }}>
            {lang("下一步")}
          </Button>
        </div>
        <div style={{
          height: 70, textAlign: "center", justifyContent: "center", alignItems: "center",
          display: this.state.counter === 0 ? "none" : "flex"
        }}>
          <Button
            disabled={!this.state.checked}
            loading={this.state.validating}
            style={{ margin: 10 }}
            // onClick={this.props.onClose.bind(this)}
            onClick={this.validate.bind(this)}
          >
            {lang("提交")}
          </Button>
        </div>
      </div>
    );

  }
}

