import React, { PureComponent } from 'react';
import { Button, Select } from 'antd';
import { message } from 'antd';
import { Input, InputNumber } from 'antd';
import { Typography, Radio, Checkbox } from 'antd';
import { Form as FormTypes } from "../config/forms/formTypes"
import { config as formConfig } from "../config/forms/v1"
import { SectionPart } from "../config/forms/formTypes"
import { LanguageIndex } from "../config/language/index"
import { lang } from '../function/lang';
import { SwapRightOutlined, CaretRightOutlined } from '@ant-design/icons';


const { Title, Paragraph, Text, Link } = Typography;
type State = {
  currentActivePageTag: string
  formData: FormTypes["data"]
  inputData: {
    [str: string]: number
  },
  pageHistory: string[]
}

interface SelectInterface {
  display: boolean
  onFinished: (input: {
    [str: string]: number
  }) => void
}


export class Form extends PureComponent<SelectInterface, State> {
  partsTitle: {
    [id in SectionPart]: {
      title: LanguageIndex,
      lable: LanguageIndex
    }
  };
  constructor(props: SelectInterface) {
    super(props);

    let defaultPageTag = ""
    for (let item of formConfig.data) { defaultPageTag = item.tag; break }
    this.state = {
      formData: formConfig.data,
      currentActivePageTag: defaultPageTag,
      inputData: {},
      pageHistory: []
    }
    this.partsTitle = {
      0: {
        title: "个人基本资料",
        lable: "第一部分"
      },
      1: {
        title: "个人及家庭病史",
        lable: "第二部分"
      },
      2: {
        title: "个人吸烟史",
        lable: "第三部分"
      }
    }
  }

  async componentDidMount() {
    this.setState({
      formData: formConfig.data
    })

  }
  nextPage(tag?: string) {
    console.log("nextPage", tag)
    if (tag) {
      console.log("即将切换页面", 1, "ID已指定", tag)
      if ("END" === tag) {
        this.props.onFinished(this.state.inputData)
      } else {
        for (let item of this.state.formData) {
          if (item.tag === tag) {
            console.log("即将切换页面", 2, "ID已指定", this.state.currentActivePageTag, item)

            this.setState({
              currentActivePageTag: item.tag,
              pageHistory: [...this.state.pageHistory, item.tag]
            })
            this.forceUpdate()
            return
          }
        }
      }
    }
    this.state.formData.some((item, id) => {
      if (item.tag === this.state.currentActivePageTag) {
        if (typeof (formConfig.data[id + 1]) !== "undefined") {
          console.log("即将切换页面", "ID下一位", this.state.currentActivePageTag, formConfig.data[id + 1])
          this.setState({
            currentActivePageTag: formConfig.data[id + 1].tag,
            pageHistory: [...this.state.pageHistory, item.tag]
          })
          this.forceUpdate()
        } else {
          this.props.onFinished(this.state.inputData)
        }
        return true
      }
    })
  }
  historyBack() {
    if (this.state.pageHistory.length > 0) {
      let lastPage = this.state.pageHistory[this.state.pageHistory.length - 1]
      let history = this.state.pageHistory
      history.pop()
      console.warn("historyBack", lastPage)
      this.setState({
        currentActivePageTag: lastPage,
        pageHistory: history
      })
      this.forceUpdate()
      // this.forceUpdate()
    }
  }

  getFormValue(tag: string) {
    return this.state.inputData[tag]
  }
  setFormValue(tag: string, value: number) {
    console.log("setting", tag, value)
    this.setState({
      inputData: {
        ...this.state.inputData,
        [tag]: value
      }
    })
    this.forceUpdate()
  }

  render() {
    console.log("pageHistory", this.state.pageHistory)
    return <div style={{ display: this.props.display ? undefined : "none", padding: 28, height: "100%", overflow: "hidden" }}>
      {Array.from(this.state.formData || [], (item, formId) => {
        if (item.tag !== this.state.currentActivePageTag) { return }
        let onClickFunc = () => { }

        return <div key={item.tag} style={{ height: "100%" }}>
          {Array.from(item.title, (title) => <Title level={3} style={{ textAlign: "center" }}>
            {lang(this.partsTitle[item.part].title)}
          </Title>)}
          <div style={{ height: `calc(100% - ${33}px - ${140}px)`, minHeight: 240 }}>
            {Array.from(item.title, (title) => <Paragraph><Text>
              {lang(title)}
            </Text></Paragraph>)}
            {(() => {
              switch (item.type) {
                case "NumberInput":
                  onClickFunc = () => {
                    console.log("form", this.state.inputData)
                    if (typeof (this.state.inputData[item.tag]) === "undefined") {
                      message.error("尚未填写");
                    } else {
                      this.nextPage()
                    }
                  }
                  return <>
                    <InputNumber
                      // step={item.step || 1}
                      step={0.1}
                      min={item.min || 0}
                      max={item.max || 100}
                      onChange={(value) => {
                        if (value === null) { return }
                        if (item.min) {
                          let rawNumber = Number(value)
                          console.log("rawNumber", rawNumber);

                          let newNumber = (rawNumber - (item.min || 0) || 0)
                          console.log("newNumber", newNumber);

                          console.debug("InputNumber", "mining", item.min, "value:", rawNumber, newNumber)
                          value = newNumber
                        }

                        if (item.fix) {
                          let newValue = Number(value.toFixed(item.fix))
                          console.debug("InputNumber", "fixing", value, newValue)
                          value = newValue
                        }

                        this.setFormValue(item.tag, value)
                      }
                      } />
                    {item.unit ? lang(item.unit) : ""}
                  </>
                case "select":
                  onClickFunc = () => {
                    console.log("form", this.state.inputData)
                    if (typeof (this.state.inputData[item.tag]) === "undefined") {
                      if (typeof (item.defaultOptionValue) !== "undefined") {
                        this.setFormValue(item.tag, item.defaultOptionValue)
                        this.nextPage()
                      } else {
                        message.error("尚未选择");
                      }
                    } else {
                      console.log("inputData", "undefined", this.state.inputData[item.tag])
                      const selected = this.state.inputData[item.tag]
                      for (let option of item.options) {
                        if (option.value === selected && option.goto) {
                          this.nextPage(option.goto)
                          return
                        }
                      }
                      this.nextPage()
                    }
                  }
                  return <>
                    <select
                      style={{ width: 200 }}
                      defaultValue={item.defaultOptionValue}
                      onChange={(event) => {
                        const value = event.target.value
                        console.log("select", "onChange", value, typeof (value), value.length)
                        if (typeof (value) === "string" && value.length === 0) {
                          return
                        }
                        const id = Number(value)
                        if (id >= -0) {
                          this.setFormValue(item.tag, id)
                        }
                      }} >
                      <option key={""} >{lang("请选择")}</option>
                      {Array.from(item.options, (selection, optionId) => {
                        return <option key={optionId} value={selection.value}>
                          {typeof selection.label === "string" ? lang(selection.label) : String(selection.label)}
                        </option>
                      })}
                    </select>
                    {item.unit ? lang(item.unit) : ""}
                    <div style={{ marginTop: 10 }}>
                      {(() => {
                        const tag = "HOW_MUCH_SMOKE_PRE_DAY"
                        if (tag === item.tag && this.getFormValue(tag) >= 5) {
                          return <>
                            <InputNumber
                              step={1}
                              min={20}
                              max={100}
                              onChange={(value) => {
                                if (value === null) { return }
                                const theValue = Number(((value)).toFixed(0))
                                this.setFormValue("HOW_MUCH_SMOKE_PRE_DAY_CUSTOM", theValue)
                              }} />
                            {item.unit ? lang(item.unit) : ""}
                          </>
                        }
                        return ""
                      })()}
                    </div>
                  </>
                case "radio":
                  onClickFunc = () => {
                    console.log("form", this.state.inputData)
                    if (typeof (this.state.inputData[item.tag]) === "undefined") {
                      console.log("selected", "未输入值")
                      if (typeof (item.defaultOptionValue) !== "undefined") {
                        this.setFormValue(item.tag, item.defaultOptionValue)
                        this.nextPage()
                      } else {
                        message.error("尚未选择");
                      }
                    } else {
                      const selected = this.state.inputData[item.tag]
                      console.log("selected", 1, "输入值：", selected)
                      for (let selection of item.options) {
                        if (selection.value === selected) {
                          console.log("selected", 2, "输入值HIT", selection)

                          if (selection.goto) {
                            this.nextPage(selection.goto)
                            return
                          }
                        }
                      }
                      this.nextPage()
                    }
                  }
                  return <>
                    <Radio.Group
                      onChange={(event) => {
                        const value = event.target.value
                        const id = Number(value)
                        if (id >= -0) {
                          this.setFormValue(item.tag, id)
                        }
                      }}
                    >
                      {Array.from(item.options, (selection, optionId) => {
                        return <Radio key={optionId} value={selection.value}>
                          {typeof selection.label === "string" ? lang(selection.label) : String(selection.label)}
                        </Radio>
                      })}

                    </Radio.Group>
                  </>
                case "checkbox":
                  onClickFunc = () => {
                    console.log("form", this.state.inputData)
                    if (typeof (this.state.inputData[item.tag]) === "undefined") {
                      console.log("selected", "未输入值")
                      if (typeof (item.defaultOptionValue) !== "undefined") {
                        this.setFormValue(item.tag, item.defaultOptionValue)
                        this.nextPage()
                      } else {
                        message.error("尚未选择");
                      }
                    } else {
                      const selected = this.state.inputData[item.tag]
                      console.log("selected", 1, "输入值：", selected)
                      for (let selection of item.options) {
                        if (selection.value === selected) {
                          console.log("selected", 2, "输入值HIT", selection)

                          if (selection.goto) {
                            this.nextPage(selection.goto)
                            return
                          }
                        }
                      }
                      this.nextPage()
                    }
                  }
                  let options: { label: string, value: number }[] = []
                  for (let opt of item.options) {
                    options.push({
                      value: opt.value,
                      label: typeof (opt.label) === "string" ? lang(opt.label) : String(opt.label)
                    })
                  }
                  return <>
                    <Checkbox.Group
                      options={options}
                      defaultValue={['Apple']}
                      onChange={(rawInput) => {
                        // const input: number[] = rawInput
                        const input = rawInput
                        let BString = ""
                        for (let opt of item.options) {
                          let checked = false
                          for (let state of input) {
                            if (state === opt.value) {
                              checked = true
                              break
                            }
                          }
                          if (checked) {
                            BString += "1"
                          } else {
                            BString += "0"

                          }
                          const finalValue = parseInt(BigInt(`0b${BString}`).toString())
                          console.log("BString", "finalValue", finalValue)
                          this.setFormValue(item.tag, finalValue)
                        }
                      }} />
                  </>
                default:
                  return "EMPTY"
              }
            })()}
          </div>
          <div className={"part-par"} style={{
            height: 70, textAlign: "center",
            display: "flex", justifyContent: "center", alignItems: "center"
          }}>
            <div style={{ display: "inline-block", fontSize: 18, color: item.part === 0 ? "#008992" : "#A3A4A8", padding: "0 10px" }}>
              <div style={{ fontWeight: 600 }}>
                {lang(this.partsTitle[0].lable)}
              </div>
              <div>
                {lang(this.partsTitle[0].title)}
              </div>
            </div>
            <div style={{ display: "inline-block", color: item.part === 0 ? "#008992" : "#A3A4A8" }}>
              <CaretRightOutlined style={{ fontSize: 32 }} />
            </div>
            <div style={{ display: "inline-block", fontSize: 18, color: item.part === 1 ? "#008992" : "#A3A4A8", padding: "0 10px" }}>
              <div style={{ fontWeight: 600 }}>
                {lang(this.partsTitle[1].lable)}
              </div>
              <div>
                {lang(this.partsTitle[1].title)}
              </div>
            </div>
            <div style={{ display: "inline-block", color: item.part !== 0 ? "#008992" : "#A3A4A8" }}>
              <CaretRightOutlined style={{ fontSize: 32 }} />
            </div>
            <div style={{ display: "inline-block", fontSize: 18, color: item.part === 2 ? "#008992" : "#A3A4A8", padding: "0 10px" }}>
              <div style={{ fontWeight: 600 }}>
                {lang(this.partsTitle[2].lable)}
              </div>
              <div>
                {lang(this.partsTitle[2].title)}
              </div>
            </div>
          </div>
          <div style={{
            height: 70, textAlign: "center",
            display: "flex", justifyContent: "center", alignItems: "center"
          }}>
            <Button onClick={this.historyBack.bind(this)}
              disabled={this.state.pageHistory.length === 0}
              style={{ margin: 10 }}>
              {lang("上一题")}
            </Button>
            <Button
              style={{ margin: 10 }}
              onClick={onClickFunc.bind(this)}>
              {lang("下一题")}
            </Button>
          </div>

          <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            display: "none"
          }} id={"devBar"} >
            <div>
              {"第"}{item.part + 1}{"部分"}
            </div>
            <div>
              <Button onClick={() => {
                this.nextPage()
              }}>
                DEV_NEXT
              </Button>
            </div>
          </div>
        </div>
      })}
      <div style={{ display: this.state.currentActivePageTag === "END" ? undefined : "none" }}>
        {"ENDED"}
      </div>

    </div>

  }
}

