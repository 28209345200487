import { LanguageIndex, Language, defaultLanguage, translation } from "../config/language/index"


function getCurrentLanguage(): Language {
   const languageTag = document.location.hash.replace("#", "")
   switch (languageTag) {
      case "en":
      case "tc":
      case "sc":
         return languageTag
      default:
         return defaultLanguage
   }
}
function hasVaildLanguage(): boolean {
   const languageTag = document.location.hash.replace("#", "")
   switch (languageTag) {
      case "en":
      case "tc":
      case "sc":
         return true
      default:
         return false
   }
}
function lang(translateKey: LanguageIndex) {
   const language = getCurrentLanguage()
   const translateTable = translation[language]
   return translateTable[translateKey]
}

export {
   lang,
   hasVaildLanguage,
   getCurrentLanguage
}