import { LanguageIndex, Language, Translate } from "./index"

const translate: Translate = {
   "HOW_OLD": "What was your birth year? ",
   "HOW_OLD_IN_REPORT": "How old are you?",
   "YOUR_GENDER": "What is your gender?",
   "MAN": "Male",
   "WOMAN": "Female",
   "EDUCATION": "What is your education level?",
   "中學或以下": "Secondary school or below",
   "大學": "University graduate",
   "碩士或以上": "Post-graduate or above",
   "HOW_TALL": "How tall are you (in meters)?",
   "HOW_WEIGHT": "How heavy are you (in kilograms)?",
   "IS_HK_RESIDENT": "Are you a Hong Kong resident?",
   "HAD_COPD": "Have you ever been diagnosed as having COPD (chronic obstructive pulmonary disease), emphysema or chronic bronchitis?",
   "HAD_TPP": "Have you ever been diagnosed as tuberculosis, pneumoconiosis or pneumonitis?",
   "HAD_CANCER": "Have you ever been diagnosed as having cancer?",
   "HAD_FAMILY_CANCER": "Have any of your biological parents, brothers, sisters, or children been diagnosed with lung cancer?",
   "FAMILY_WHO_HAD_CANCER": "Which of them is/are diagnosed with lung cancer? (can choose multiple options)",
   "SMOKE": "Do you currently smoke cigarettes?",
   "WAS_SMOKE": "Do you previously smoke cigarettes?",
   "TIME_QUIT_SMOKE": "How long have you been stopped smoking?",
   "HOW_MUCH_SMOKE_PRE_DAY": "During your lifetime of smoking, on average, how many cigarettes have you smoked a day?",
   "HOW_LONG_DID_YOU_SMOKE": "How many years have you been smoking?",
   "单位(年)": "years",
   "单位(cm)": "cm",
   "单位(m)": "meters",
   "单位(岁)": "years old",
   "单位(kg)": "kilograms",
   "单位(枝)": "cigarettes",
   "YES": "Yes",
   "NO": "No",
   "before1932": "Earlier than 1932",
   "父親": "Father",
   "母親": "Mother",
   "兄弟": "Brother",
   "姊妹": "Sister",
   "子女": "Children",
   "15年或以下": "15 years or less",
   "10-15年": "10-15 years",
   "15年以上": "more than 15 years",
   "不適用": "Not applicable",
   "1-5枝": "1-5 cigarettes",
   "6-10枝": "6-10 cigarettes",
   "11-15枝": "11-15 cigarettes",
   "16-20枝": "16-20 cigarettes",
   "輸入香煙枝數": "more than 20 cigarettes, please enter the number of cigarettes",
   "20枝以上": "more than 20 cigarettes",
   "简介": "Introduction",
   "简介第1段落": "Lung cancer is the leading cause of cancer deaths in Hong Kong, accounting for one in four cancer deaths in 2019.¹",
   "简介第2段落": `Smoking is the major cause of lung cancer. Even former smokers remain at high risk of having lung cancer at 25 years after quitting.² Yet, lung cancer is not just a smokers’ disease. Around one third of all lung cancer patients are never-smokers in East Asia³ with risk factors such as family history`,
   "简介第2段落_上标": `4,5`,
   "简介第2段落_尾": `. Thus, lung cancer can occur in both smokers, former smokers, and non-smokers with certain risk factors. `,
   "简介第3段落": `There are usually no signs or symptoms in the early stages of lung cancer. Symptoms develop as the disease progresses.⁶  Therefore, lung cancer is often being detected at an advanced stage⁷ when prognosis is worse.  If people with lung cancer can be diagnosed at stage I, their likelihood of surviving 5 years is between 68-92% compared to under 10% at stage IV.⁸`,
   "简介第4段落": `Lung cancer screening allows lung cancer to be detected and diagnosed at an earlier stage of the disease, with a better prognosis, earlier treatment intervention and higher 5 year survival, in high risk population.`,
   "简介第4段落_上标1": "9,10",
   "简介第4段落_尾1": " People in high risk of lung cancer may consider to undergo lung cancer screening to understand whether they have lung cancer. Screening is the use of tests or exams to find a disease in people who do not have symptoms.¹¹ Low-dose computed tomography (LDCT) is the only clinical examination test proven to date to be effective in screening for lung cancer. Several international clinical studies have demonstrated its effectiveness in identifying early stage lung cancer when symptoms are absent and lower lung cancer mortality rate.",
   "简介第4段落_上标2": "9,10",
   "简介第4段落_尾2": "",

   "简介第5段落": `However, not all individuals are necessary to undergo lung cancer screening with LDCT test(s). Current evidence shows only people with the risk factors of lung cancer should undergo lung cancer screening with LDCT test(s).`,
   "简介第5段落_上标": `4,5,9,10`,
   "简介第6段落": `Complete the assessment by clicking the button below to understand if you are eligible for a free LDCT lung cancer screening. Eligible person  may get a free medical consultation and a LDCT screening test. Free quotas are limited and provided at first-come-first-served basis. `,
   "简介第7段落": `If you have any questions about lung cancer risk or lung cancer screening, please consult your family doctor.`,
   "Reference": "Reference",
   "第1部分": "Part 1",
   "第一部分": "Part 1",
   "个人基本资料": "About Yourself",
   "第2部分": "Part 2",
   "第二部分": "Part 2",
   "个人及家庭病史": "Medical History",
   "第3部分": "Part 3",
   "第三部分": "Part 3",
   "个人吸烟史": "Smoking History",
   "上一题": "Previous",
   "下一题": "Next",
   "重要事项": "Important Notes",
   "重要事项段落_1": "1.“#seethefact” is a subsidiary disease awareness campaign of A Breath of Hope (“ABOH”) which is co-supported by AstraZeneca Hong Kong(“AZHK”) and Hong Kong Lung Cancer Study Group.",
   "重要事项段落_2": "2.This lung cancer screening program is a partnership between AZHK and Asian Cancer Alliance for Research and Education (“ACARE”).",
   "重要事项段落_3": "3.AZHK funds the service of low-dose computed tomography (“LDCT”) screening and pre-LDCT medical consultation and while ACARE funds the post-LDCT medical consultation. ACARE is responsible for engaging qualified third parties to provide all the medical consultation service and LDCT screening service.",
   "重要事项段落_4": "4.All participants are voluntary to join the lung cancer risk assessment, medical consultation and LDCT.",
   "重要事项段落_5": "5.Eligible participant may receive the pre-LDCT medical consultation, LDCT screening and post-LDCT medical consultation for free. If there is subsequent follow-up recommendations by healthcare professional after LDCT screening, it will be the participants’ own responsibility to go though such recommendations at own cost and risk.",
   "重要事项段落_6": "6.Due to the limit of free consultation and LDCT screening quota, not all high risk participants will receive free consultation or LDCT screening after this risk assessment. High risk participants who do not receive notice to take free consultation or LDCT screening shall decide whether they should go for LDCT screening and it will be their own responsibility of the high risk participant to perform LDCT screening at own cost and risk.",
   "重要事项段落_7": "7.It is the responsibility of the participant to sign up with ACARE for the arrangement of the pre-LDCT medical consultation and LDCT screening if the participant is eligible for the free LDCT service.",
   "重要事项段落_8": "8.For any eligible participant who fails to or is missed to be arranged for the pre- and post-LDCT medical consultation and LDCT screening, ABOH, AZHK or ACARE shall not be liable for any loss from the participant.",
   "重要事项段落_9": "9.If the participant is contraindicated with LDCT screening or identified as not eligible for LDCT screening during pre-LDCT medical consultation, the participant will not proceed to free LDCT screening or refund on any unused services.",
   "重要事项段落_10": "10.Participant hereby agrees and consents that each of ABOH, AZHK or ACARE may publish the aggregated data of all participants’ lung cancer risk assessment result, LDCT screening outcome, medical history and/or smoking history in the future.",
   "重要事项段落_11": "11.Medical consultation may be conducted by general practitioner, specialist of family medicine, specialist of respiratory medicine or specialist of clinical oncology.",
   "重要事项段落_12": "12.This lung cancer risk assessment is designed based on several published international studies and endorsed by a panel local healthcare professionals.",
   "重要事项段落_13": "13.The lung cancer risk calculation is based on the answers participant provided. The result may not be completely representative of the level of lung cancer risk of each participant.",
   "重要事项段落_14": "14.Participant who gets a high risk result does not necessarily mean he/she  has lung cancer now or in the future. Similarly, there is also possibility that participant who gets a low risk result may have lung cancer now or in the future.",
   "重要事项段落_15": "15.This lung cancer risk assessment result is for reference only. ABOH, AZHK or ACARE shall not be liable for any consequence from the participant if there is false positive or false negative result, nor shall any of them be liable for any consultation or advice given during pre-LDCT medical consultation, LDCT screening and post-LDCT medical consultation.",
   "重要事项段落_16": "16.If the participant has any questions regarding LDCT screening or/and lung cancer risk assessment, it is his/her own responsibility to consult his/her doctor before participating this lung cancer screening program.",
   "上一步": "Previous",
   "下一步": "Next",
   "個人資料私隱政策聲明": "Personal Data Privacy Policy",
   "隐私段落_1": "1.Participant can decide whether to provide information for this lung cancer risk assessment.",
   "隐私段落_2": "2.AstraZeneca Hong Kong Limited and its lung cancer disease awareness campaign “A Breath of Hope” will transfer the lung cancer risk assessment result of the participant to Asian Cancer Alliance for Research and Education for the subsequent low-dose computed tomography of lung and medical consultation arrangement.",
   "隐私段落_3_前": "3.If participant does not want the lung cancer risk assessment result to be shared with Asian Cancer Alliance for Research and Education, please send an email request to the Privacy Commissioner for Personal Data of “A Breath of Hope” at",
   "隐私段落_3_邮箱": " info@livingwithlungcancer.asia",
   "隐私段落_3_后": ". “A Breath of Hope” will respond within 40 days upon receiving the request. If we fail to process the request within the aforementioned period, “A Breath of Hope” shall notify the reader within the aforementioned period. However, it also means the participant gives up any subsequent low-dose computed tomography of lung and medical consultation (if applicable).",
   "隐私段落_4_前": "4.The use mentioned above must comply with the company's Privacy Policy Statement, which can be read at the Privacy Policy located at the footer of this website or obtained by contacting the Company via e-mail, attention to the Privacy Commissioner for Personal Data of “A Breath of Hope”, at",
   "隐私段落_4_邮箱": " info@livingwithlungcancer.asia",
   "隐私段落_4_后": ".",
   "隐私复选框_1": "I have read in details the above personal data privacy policy, privacy policy of “A Breath of Hope” and above important notes and I fully understand and agree to the content.",
   "隐私复选框_2": "(If the above boxes are not checked, “A Breath of Hope” does not have my consent, the lung cancer risk assessment will not be proceeded.)",
   "提交": "Submit",

   "感谢参与": "Thanks for participating the assessment!",
   "你患上肺癌的风险": "Your risk of developing lung cancer",
   "较低": " may be low",
   "较高": " may be high",
   "下载评估结果": "Download assessment report",
   "报告页脚信息1": "If you have any questions related to lung cancer risk or lung cancer screening, please consult your family doctor.",
   "报告页脚信息2": "If you would like to understand more about lung cancer, you can browse A Breath of Hope website.",
   "报告页脚信息3_前": "If you have any questions related to this program, please email to",
   "报告页脚信息3_邮箱": " events@livingwithlungcancer.asia",
   "报告页脚信息3_后": ".",

   "结果无风险段落_1": "According to your responses, you fulfil the criteria of this free low-dose computed tomography (“LDCT”) lung cancer screening program. ",
   "结果免费段落_1": "According to your responses, your risk of having lung cancer may be higher than other people. You may consider to seek for medical consultation and perform a low-dose computed tomography (“LDCT”) screening to further understand your lung health.",
   "结果免费段落_2_前": "A Breath of Hope has collaborated with Asian Cancer Alliance for Research and Education (“ACARE”) to provide free medical consultation and Low dose CT screening to eligible participant. Please download your assessment result on this page. Kindly message ACARE customer service through WhatsApp at ",
   "结果免费段落_2_中": " +852-64685836 ",
   "结果免费段落_2_后": "during office hours (from Monday to Friday during 10:00 am to 6:00 pm) within seven working days to schedule a session for the free medical consultation and LDCT screening. Please bring this assessment result with you when you meet with the doctor for reference.  Free quotas are limited and provided at first-come-first-served basis. No change of appointment once it is confirmed.",
   "结果付费段落_1": "According to your responses, your risk of having lung cancer may be higher than other people. You may consider to seek for medical consultation and perform a low-dose computed tomography (“LDCT”) screening to further understand your lung health.",
   "结果付费段落_2": "If you have any question related to lung cancer risk or lung cancer screening, please consult your family doctor.",

   "报告段落_1": "Thanks for participating the assessment! Below are the responses you have provided.",
   "报告段落_2_免费": "A Breath of Hope is collaborating with Asian Cancer Alliance for Research and Education (“ACARE”) to provide free medical consultation and low-dose computed tomography (“LDCT”) screening to eligible participant.",
   "报告段落_3_低": "According to your responses, your risk of having lung cancer may be low.",
   "报告段落_3_高": "According to your responses, your risk of having lung cancer may be higher than other people.",
   "报告段落_3_中_免费":"Kindly message ACARE customer service through WhatsApp at +852-64685836 during office hours (from Monday to Friday during 10:00 am to 6:00 pm) within seven working days to schedule a session for the free medical consultation and LDCT screening. ",

   "报告段落_3_前_免费": "Kindly message ACARE customer service through ",
   "报告段落_3_电话_免费":"WhatsApp at +852-64685836 ",
   "报告段落_3_前_免费后":"during office hours (from Monday to Friday during 10:00 am to 6:00 pm) within seven working days to schedule a session for the free medical consultation and LDCT screening.",
   
   "报告段落_3_后_免费": "Please bring this assessment result with you when you meet with the doctor. ",
   "免费名额有限" : "Free quotas are limited and provided at first-come-first-served basis. No change of appointment once it is confirmed.",
   "报告段落_3_中_付费": " You may consider seeking for medical consultation and perform a low-dose computed tomography (“LDCT”) screening to further understand your lung health.",
   "报告段落_3_后_付费": "",
   "报告段落_4": "If you have any question related to lung cancer risk or lung cancer screening, please consult your family doctor.",

   "参考编号": "Reference No",
   "报告日期": "Report Date",
   "请选择": "please choose",
   "报告页脚信息4": "To save your lung cancer risk assessment results, please download here before leaving or closing this page",
   "立即进行肺癌风险评估": "Carry out assessment immediately",
   // 1
   "1结果上":"According to your responses, you fulfil the criteria of this ","1结果下":"low-dose computed tomography (“LDCT”) lung cancer screening program.",


   //2.符合LDCT筛查标准，但不符合免费筛选计划的资格
   "符合不免费" : "According to your responses, you may need to perform a lung cancer screening. You may consider seeking for medical consultation and perform a low-dose computed tomography (“LDCT”) screening to further understand your lung health.",

   "不包含" : "However, this ",
   "免费":"free ",
   "不包含后": "LDCT lung cancer screening program is not covering the following people:",

   "分类1":"1.	 Non-Hong Kong resident",
   "分类2":"2.	 Aged less than 50 years old or more than 80 years old",
   "分类3":"3.  Have been diagnosed as having tuberculosis, pneumoconiosis or pneumonitis",
   "分类4":"4.  Have been diagnosed as having cancer",
   "34类":"If you belong to the third or fourth category above, please discuss with your attending doctor whether you need to be screened for lung cancer.",

   // 不符合LDCT筛查标准
   "不符合条件":"According to your answer, you are not currently eligible for this ",
   "不符合条件后":"low-dose computed tomography (“LDCT”) lung cancer screening program.",
   "风险人士":"According to some international research, lung cancer screening through LDCT can be considered for people with the following risk factors",
   "风险人士后-上标":"1-3",
   "风险人士后-冒号":":",
   "年龄介乎":"Age between 50-80 years old",
   "小于15年":"Currently smoking (or have quitted smoking for no more than 15 years) and have a smoking history of 20 pack-year or more",
   "大于15年":"Currently smoking (or have quitted smoking for no more than 15 years) and have a smoking history of less than 20 pack-year, together with other risk factors such as history of COPD (chronic obstructive pulmonary disease), emphysema, chronic bronchitis, or first-degree family lung cancer history",
   "没有吸烟习惯" : "Have never smoked (or have quitted smoking for more than 15 years and have a smoking history of less than 10 pack-year), and have a first-degree family history of lung cancer ",
   "风险可变" : "The risk of lung cancer is variable. When the above factors change, especially when you are still smoking or you family member has been diagnosed with lung cancer, you may consider performing lung cancer risk assessment again. If you have any question, please discuss with your family doctor.",
  
   "定期检查" : "Even so, you can still reduce the risk of lung cancer by changing controllable risk factors, such as: do not smoke and avoid second-hand smoke, avoid long-term exposure to air pollutants or any known carcinogens.",
   "上标12":"1,2",
   "上标45":"4,5",
   "定期检查后":" If the above-mentioned risk factors cannot be avoided, regular physical examinations may be considered.",
   "健康饮食" : "In addition, a healthy diet, such as regular consumption of fruits and vegetables, and moderate exercise, are beneficial to health and may help reduce the risk of lung cancer.",
   "上标1":"1",
   "上标4":"4",
   "参考资料":"Reference:",
   "参考资料1":"1.	Tammemagi MC, et al. Lancet Oncol 2022; 23:138-48.",
   "参考资料2":"2.	The US Preventive Services Task Force. JAMA 2021; 325(10):962-970.",
   "参考资料3":"3.	Yang P. Presentation at 2020 World Conference on Lung Cancer Singapore, 28-31 January 2021.",
   "参考资料4":"4.	American Cancer Society. Can lung cancer be prevented? https://www.cancer.org/cancer/lung-cancer/causes-risks-prevention/prevention.html. Accessed 18 June 2021. ",
   "参考资料5":"5.	National Cancer Institute. Lung Cancer Prevention (PDQ®)-patient version. https://www.cancer.gov/types/lung/patient/lung-prevention-pdq. Accessed 11 January 2022.",

   //符合LDCT筛查标准但免费配额用尽
   "免费配额用尽" : "According to your answer, you may need to perform a lung cancer screening. You may consider seeking for medical consultation and perform a low-dose computed tomography (“LDCT”) screening to further understand your lung health. ",
   "名额已满" : "However, the free quota of this LDCT lung cancer screening has been fully consumed. ",
   "名额已满前" : "However, the ",
   "名额已满后" : "quota of this LDCT lung cancer screening has been fully consumed."
}



export { translate }
