import { LanguageIndex, Language, Translate } from "./index"


const translate: Translate = {
   "HOW_OLD": "你的出生年份是？",
   "HOW_OLD_IN_REPORT": "你的年龄是？",
   "YOUR_GENDER": "你的性别是?",
   "MAN": "男",
   "WOMAN": "女",
   "EDUCATION": "你的教育程度是?",
   "中學或以下": "中学或以下",
   "大學": "大学",
   "碩士或以上": "硕士或以上",
   "HOW_TALL": "你的身高是多少米?",
   "HOW_WEIGHT": "你的体重是多少公斤?",
   "IS_HK_RESIDENT": "你是否香港居民?",
   "HAD_COPD": "你是否曾经被诊断患上慢性阻塞性肺病、肺气肿或慢性支气管炎?",
   "HAD_TPP": "你是否曾经被诊断患上肺痨、肺尘埃沉着病(或间称肺积尘病)或肺炎?",
   "HAD_CANCER": "你是否曾经被诊断患上癌症?",
   "HAD_FAMILY_CANCER": "你的亲生父母、姊妹、兄弟或子女是否曾经被诊断患上肺癌?",
   "FAMILY_WHO_HAD_CANCER": "请选择曾经被诊断患上肺癌的亲人是? (可多项选择)",
   "SMOKE": "你现在是否有吸食香烟的习惯?",
   "WAS_SMOKE": "你是否曾经有吸食香烟的习惯?",
   "TIME_QUIT_SMOKE": "你已戒烟多少年?",
   "HOW_MUCH_SMOKE_PRE_DAY": "根据你吸烟的习惯，平均每天会吸食多少支香烟?",
   "HOW_LONG_DID_YOU_SMOKE": "你曾经或已经有多少年的吸烟习惯?",
   "单位(年)": "年",
   "单位(cm)": "厘米",
   "单位(m)": "米",
   "单位(岁)": "岁",
   "单位(kg)": "公斤",
   "单位(枝)": "支",
   "YES": "是",
   "NO": "否",
   "before1932": "比1932早",
   "父親": "父亲",
   "母親": "母亲",
   "兄弟": "兄弟",
   "姊妹": "姊妹",
   "子女": "子女",
   "15年或以下": "15年或以下",
   "10-15年": "10-15年",
   "15年以上": "15年以上",
   "不適用": "不适用",
   "1-5枝": "1-5枝",
   "6-10枝": "6-10枝",
   "11-15枝": "11-15枝",
   "16-20枝": "16-20枝",
   "輸入香煙枝數": "20支以上，请输入香烟支数",
   "20枝以上": "20支以上",
   "简介": "简介",
   "简介第1段落": "肺癌是香港头号癌症杀手，在2019年，每四名因癌症死亡的病人里便有一位是肺癌患者。¹",
   "简介第2段落": "吸烟是引致肺癌的一个主要原因。 根据一个美国研究显示，即使一些已戒烟25 年的人士仍然有高风险患上肺癌。²然而，肺癌却不仅仅是一个吸烟人士才会患上的疾病，在东亚地区中，约有三分之一的肺癌患者是从不吸烟的人士³，他们或带有其他的风险因素如有肺癌家族史",
   "简介第2段落_上标": `4,5`,
   "简介第2段落_尾": `。 因此，吸烟人士、已戒烟人士和非吸烟人士都同样有患上肺癌的风险。`,
   "简介第3段落": "肺癌在发展初期通常没有任何迹象或症状，当病情进展时，症状便会伴随而来⁶，因此，肺癌通常在已发展至晚期时才被发现⁷，而预计治疗结果通常较差。如果肺癌患者可以在第 I 期被诊断出来，他们的五年存活率介乎在68-92%，而第 IV 期的五年存活率则低于10%。⁸",
   "简介第4段落": `肺癌筛查可以在肺癌还是在早期的时候把肺癌发现和诊断出来，让一些高风险人士可及早发现患病并尽快得到适切的治疗，从而提高患者的五年存活率。`,
   "简介第4段落_上标1": "9,10",
   "简介第4段落_尾1": "肺癌高风险人士可考虑进行肺癌筛查，以了解他们是否患有肺癌。筛查是指通过一些检测，将仍未有症状的患者找出来。¹¹低剂量电脑扫描(LDCT)是迄今为止唯一被证明可有效筛查肺癌的临床检查方法。几项国际临床研究已证明LDCT在肺癌高风险人士仍未有症状时识别早期肺癌和降低肺癌死亡率方面的有效性。",
   "简介第4段落_上标2": "9,10",
   "简介第4段落_尾2": "",
   "简介第5段落": "然而，并非所有人士都需要进行LDCT肺癌筛查。目前的证据表明，只有具肺癌风险因素的人士才需通过LDCT测试进行肺癌筛查。",
   "简介第5段落_上标": `4,5,9,10`,
   "简介第6段落": "立即点选下面的评估按钮，然后回答几条简单回题以了解你是否符合本免费低剂量电脑扫描肺癌筛查计划的条件。符合资格的高风险人士或有机会获得免费医疗咨询和LDCT筛查，免费名额有限，先到先得。",
   "简介第7段落": "如果你对患上肺癌的风险因素或肺癌筛查有任何疑问，请咨询你的家庭医生。",
   "Reference": "参考资料",
   "第1部分": "第1部分",
   "第一部分": "第一部分",
   "个人基本资料": "个人基本资料",
   "第2部分": "第2部分",
   "第二部分": "第二部分",
   "个人及家庭病史": "个人及家庭疾病史",
   "第3部分": "第3部分",
   "第三部分": "第三部分",
   "个人吸烟史": "个人吸烟史",
   "上一题": "上一题",
   "下一题": "下一题",
   "重要事项": "重要事项",
   "重要事项段落_1": "1.「#看透肺事」是一个附属于「呼吸希望」的疾病教育计划，而「呼吸希望」是由英国阿斯利康药厂(香港) (下称“AZHK”)及香港肺癌学会共同支持。 ",
   "重要事项段落_2": "2.此肺癌筛查计划是一项由AZHK与亚洲癌症研究与教育联盟“下称ACARE”的合作项目。",
   "重要事项段落_3": "3.AZHK会资助低剂量电脑断层扫描(下称“LDCT”) 筛查和LDCT 前的医疗咨询服务，而 ACARE 则会资助LDCT 后的医疗咨询，并负责聘请合格的第三方提供所有医疗咨询和 LDCT服务。",
   "重要事项段落_4": "4.所有参加者皆为自愿参与肺癌风险评估、医疗咨询及LDCT筛查服务。",
   "重要事项段落_5": "5.符合条件的参加者可免费接受LDCT筛查服务和LDCT前及后的医疗咨询。 在进行LDCT筛查 后，若医护人员按参加者的状况提出了任何后续建议，参加者可自行选择是否接纳，同时亦是由参加者自行承担这些建议的费用和风险。",
   "重要事项段落_6": "6.由于LDCT筛查服务和LDCT前及后的医疗咨询的免费名额有限，并非所有高风险参加者在完成此肺癌风险评估后得到免费的LDCT筛查服务和LDCT前及后的医疗咨询。未能得到免费的LDCT筛查服务或医疗咨询的高风险参加者可自行选择是否进行LDCT筛查，同时亦是由参加者自行承担进行LDCT筛查的费用和风险。",
   "重要事项段落_7": "7.符合条件的参加者需按指示自行联络ACARE安排免费的LDCT筛查服务和LDCT前的医疗咨询。",
   "重要事项段落_8": "8.若符合条件的参加者因任何原因而未能或错失了免费的LDCT筛查服务或LDCT前/后的医疗咨询，ABOH、AZHK或ACARE将不会对参加者的任何损失承担任何责任。",
   "重要事项段落_9": "9.如果参加者在 LDCT 前的医疗咨询中被确定为不适合进行 LDCT筛查，则参加者将不会继续进行免费 LDCT 筛查服务，亦不会得到任何未使用的服务的退款。",
   "重要事项段落_10": "10.参加者同意及允许ABOH、AZHK或ACARE在未来可刊登或公布肺癌风险评估结果、LDCT筛查结果、病史和/或吸烟史的汇总数据。",
   "重要事项段落_11": "11.医疗咨询或会由普通科医生、家庭医学专科医生、呼吸系统科专科医生或临床肿瘤科专科医生进行。",
   "重要事项段落_12": "12.此肺癌风险评估是根据数个国际研究起拟，并已由一群本地医护人员审阅。",
   "重要事项段落_13": "13.此肺癌风险评估的计算是按参加者提供的答案来作评估，评估结果或未能完全代表个别参加者患上肺癌的风险。",
   "重要事项段落_14": "14.经评估为高风险的参加者并不代表现在或将来有机会患上肺癌，同样地，经评估为低风险的参加者现在或将来或有机会患上肺癌。",
   "重要事项段落_15": "15.此肺癌风险评估的结果只作参考用，ABOH、AZHK或ACARE将不会对参加者因假阳性或假阴性的任何后果承担任何责任，同时亦不对在 LDCT筛查服务或LDCT前/后的医疗咨询期间提供的任何咨询或建议负责。",
   "重要事项段落_16": "16.如参加者就LDCT筛查或/及肺癌风险评估有任何疑问，是由参加者自行负责在参与此肺癌筛查计划前向医生查询。",
   "上一步": "上一步",
   "下一步": "下一步",
   "個人資料私隱政策聲明": "个人资料私隐政策声明",
   "隐私段落_1": "1.参加者可自行决定是否提供资料以参加这个肺癌风险评估。",
   "隐私段落_2": "2.阿斯利康香港有限公司及其肺癌关注计划「呼吸希望」会将参加者的肺癌风险评估结果转移至亚洲癌症研究与教育联盟作后续肺部低剂量电脑断层扫描及医疗咨询服务安排之用。 ",
   "隐私段落_3_前": "3.如参加者不欲将其肺癌风险评估结果转移至亚洲癌症研究与教育联盟，请以电邮通知「呼吸希望」，并注明「致呼吸希望私隐专员」（电邮地址：",
   "隐私段落_3_邮箱": "info@livingwithlungcancer.asia",
   "隐私段落_3_后": "）。「呼吸希望」会于接获有关要求之日起40天内作出回覆。如「呼吸希望」未能于前述限期内处理有关要求，「呼吸希望」应于前述限期内通知参加者。然而，参加者将自动放弃后续低剂量电脑断层扫描及医疗咨询服务（如适用）。 ",
   "隐私段落_4_前": "4.上述用途均需符合阿斯利康香港有限公司私隐政策声明，该声明可参阅本网站底部的私隐政策声明，或透过向本公司以电邮索取，并注明「致呼吸希望私隐专员」（电邮地址：",
   "隐私段落_4_邮箱": "info@livingwithlungcancer.asia ",
   "隐私段落_4_后": "）。",
   "隐私复选框_1": "本人已详细阅读上述个人资料私隐政策声明、「呼吸希望」网站的私隐政策声明及上述重要事项，并确认本人完全明白及同意有关内容。 ",
   "隐私复选框_2": "(如以上方格没有打剔，「呼吸希望」未得到本人同意，有关本人肺癌风险将不会被评估。)",
   "提交": "提交",
   "感谢参与": "感谢你参与本评估!",
   "你患上肺癌的风险": "你患上肺癌的风险",
   "较低": "较低",
   "较高": "较高",
   "下载评估结果": "下载评估结果",
   "报告页脚信息1": "如果你对肺癌风险或肺癌筛查有任何疑问，请咨询你的家庭医生。",
   "报告页脚信息2": "如果你想了解更多关于肺癌的资讯，可浏览呼吸希望网站。",
   "报告页脚信息3_前": "如果你对此计划有任何疑问，请发送电子邮件至",
   "报告页脚信息3_邮箱": " events@livingwithlungcancer.asia",
   "报告页脚信息3_后": "。",
   "结果无风险段落_1": "根据你的回答，你符合本免费低剂量电脑扫描肺癌筛查计划的条件。",
   "结果免费段落_1": "根据你的回答，你患上肺癌的风险可能较其他人高，你可以考虑寻求医疗咨询并进行低剂量电脑断层扫描 (下称“LDCT”) 筛查，以进一步了解你的肺部健康状况。",
   "结果免费段落_2_前": "呼吸希望与亚洲癌症研究与教育联盟（下称“ACARE”）合作，为符合条件的参与者提供免费的医疗咨询及LDCT筛查。请在本页面下载你的评估结果，并请在七个工作日内，于办公时间（星期一至五早上10:00 至下午 6:00）内透过Whatsapp发送讯息至",
   "结果免费段落_2_中": "+852-64685836",
   "结果免费段落_2_后": "联络ACARE客户服务人员，以安排免费的医疗咨询和LDCT筛查.请在面见医生时带同此评估结果。免费名额有限，先到先得，预约一经确认后恕不更改。",
   "结果付费段落_1": "根据你的回答，你患上肺癌的风险可能较其他人高，你可以考虑寻求医疗咨询并进行低剂量电脑断层扫描 (下称“LDCT”) 筛查，以进一步了解你的肺部健康状况。",
   "结果付费段落_2": "如果你对肺癌风险或肺癌筛查有任何疑问，请咨询你的家庭医生。",
   "报告段落_1": "感谢你参与本评估，以下是你提供的答案。",
   "报告段落_2_免费": "呼吸希望与亚洲癌症研究与教育联盟（下称“ACARE”）合作，为符合条件的参加者提供免费的医疗咨询及低剂量电脑断层扫描 (下称“LDCT”)  筛查。",
   "报告段落_3_低": "根据你的回答，你患上肺癌的风险可能较低。",
   "报告段落_3_高": "根据你的回答，你患上肺癌的风险可能较其他人高，",
   "报告段落_3_中_免费":"请在七个工作日内，于办公时间（星期一至五早上10:00 至下午 6:00）内透过Whatsapp 发送讯息至 +852-64685836 联络ACARE客户服务人员，以安排免费的医疗咨询和LDCT筛查。",

   "报告段落_3_前_免费": "请在七个工作日内，于办公时间（星期一至五早上10:00 至下午 6:00）内透过",
   "报告段落_3_电话_免费":"Whatsapp 发送讯息至 +852-64685836 ",
   "报告段落_3_前_免费后":"联络ACARE客户服务人员，以安排免费的医疗咨询和LDCT筛查。",
   
   "报告段落_3_后_免费": "请在面见医生时带同此评估结果，供医生参考",
   "免费名额有限" : "免费名额有限，先到先得，预约一经确认后恕不更改。",
   "报告段落_3_中_付费": "你可以考虑寻求医疗咨询并进行低剂量电脑断层扫描筛查，",
   "报告段落_3_后_付费": "以进一步了解你的肺部健康状况。",
   "报告段落_4": "如果你对患上肺癌的风险或肺癌筛查有任何进一步的疑问，请咨询你的家庭医生。",
   "参考编号": "参考编号",
   "报告日期": "报告日期",
   "请选择": "请选择",
   "报告页脚信息4": "如需保存你的肺癌风险评估结果，请在离开或关闭本页面前在此处下载",
   "立即进行肺癌风险评估": "立即进行评估",

   // 1
   "1结果上":"根据你的回答，你符合本","1结果下":"低剂量电脑扫描肺癌筛查计划的条件。",

   
   //2.符合LDCT筛查标准，但不符合免费筛选计划的资格

   "符合不免费" : "根据你的回答，你可能需要进行肺癌筛查，你可以考虑寻求医疗咨询并进行低剂量电脑扫描筛查，以进一步了解你的肺部健康状况。",
   "不包含" : "不过，本",
   "免费":"免费",
   "不包含后": "低剂量电脑扫描肺癌筛查计划并不涵盖下列人士：",

   "分类1":"1.	 非香港居民",
   "分类2":"2.	 年龄少于50岁或超过80岁",
   "分类3":"3.	 曾经被诊断患上肺痨、肺尘埃沉着病(或称肺积尘病)或肺炎",
   "分类4":"4.	 曾经被诊断患上癌症",
   "34类":"如果你是属于上述第三或第四类人士，请与你的主诊医生讨论是否需要进行肺癌筛查。",


      // 不符合LDCT筛查标准
      "不符合条件":"根据你的回答，你目前尚未符合本",
      "不符合条件后":"低剂量电脑扫描肺癌筛查计划的条件。",
      "风险人士":"根据国际研究，透过低剂量电脑素描的肺癌筛查可考虑应用于有下列风险因素的人士",
      "风险人士后-冒号":"：",
      "风险人士后-上标":"1-3",
      "年龄介乎":"年龄介乎50-80岁",
      "小于15年":"现有吸烟习惯(或已戒烟不超过15年)并有20包年或以上的吸烟史",
      "大于15年":"现有吸烟习惯(或已戒烟不超过15年)并有少于20包年的吸烟史，则同时要考虑是否曾确诊患上慢性阻塞性肺病、肺气肿、慢性支气管炎或有直系家族肺癌家族史",
      "没有吸烟习惯" : "从来没有吸烟习惯(或已戒烟超过15年并有少于10包年的吸烟史)，同时有直系家族的肺癌家族史",
      "风险可变" : "而肺癌风险是可变的，当上述因素改变时，尤其当你仍在吸烟或有亲人患上肺癌等，你可考虑再次进行肺癌风险评估。如有疑问，请与你的家庭医生讨论。",
      "定期检查" : "即便如此，你仍可透过改变可控的风险因素，降低肺癌发生的风险，例如：不要吸烟并避免吸入二手烟、长时间处于空气污染的室内外环境、长期暴露在各种已知致癌物质的工作环境等。如果无法避免上述危险因素，可考虑定期进行身体健康检查。",
      "上标12":"1,2",
      "上标45":"4,5",
      "定期检查后":" 如果无法避免上述危险因素，可考虑定期进行身体健康检查。",
      "健康饮食" : "另外，健康饮食，比如经常食用水果和蔬菜，以及进行适量运动，均会对健康有益，可能帮助降低罹患肺癌的风险。",
      "上标1":"1",
      "上标4":"4",
      "参考资料":"参考资料：",
      "参考资料1":"1.	Tammemagi MC, et al. Lancet Oncol 2022; 23:138-48.",
      "参考资料2":"2.	The US Preventive Services Task Force. JAMA 2021; 325(10):962-970.",
      "参考资料3":"3.	Yang P. Presentation at 2020 World Conference on Lung Cancer Singapore, 28-31 January 2021.",
      "参考资料4":"4.	American Cancer Society. Can lung cancer be prevented? https://www.cancer.org/cancer/lung-cancer/causes-risks-prevention/prevention.html. Accessed 18 June 2021. ",
      "参考资料5":"5.	National Cancer Institute. Lung Cancer Prevention (PDQ®)-patient version. https://www.cancer.gov/types/lung/patient/lung-prevention-pdq. Accessed 11 January 2022.",



      //符合LDCT筛查标准但免费配额用尽
      "免费配额用尽" : "根据你的回答，你可能需要进行肺癌筛查，你可以考虑寻求医疗咨询并进行低剂量电脑断层扫描筛查，以进一步了解你的肺部健康状况。",
      "名额已满" : "不过，本免费低剂量电脑扫描肺癌筛查计划的名额已满，感谢你的支持。",
      "名额已满前" : "不过，本",
      "名额已满后" : "低剂量电脑扫描肺癌筛查计划的名额已满，感谢你的支持。"
}



export { translate }
