import { translate as tcTranslate } from "./zh-HK"
import { translate as scTranslate } from "./zh-CN"
import { translate as enTranslate } from "./en-US"
// Unicode中，有一些特殊的上标 ，如⁰¹²³⁴⁵⁶⁷⁸⁹⁺⁻⁼⁽⁾ⁿⁱ


export type Language = "tc" | "sc" | "en"
export const defaultLanguage: Language = "tc"


export type LanguageType = {
   [str in LanguageIndex]: string
}

export type LanguageIndex = "HOW_OLD" | "HOW_OLD_IN_REPORT" | "YOUR_GENDER" | "MAN" | "WOMAN" |
   "中學或以下" | "大學" | "碩士或以上" | "EDUCATION" | "HOW_TALL" | "HOW_WEIGHT" |
   "IS_HK_RESIDENT" | "HAD_COPD" | "HAD_TPP" | "HAD_CANCER" |
   "HAD_FAMILY_CANCER" | "FAMILY_WHO_HAD_CANCER" | "SMOKE" | "WAS_SMOKE" |
   "TIME_QUIT_SMOKE" | "HOW_MUCH_SMOKE_PRE_DAY" | "HOW_LONG_DID_YOU_SMOKE" |
   "YES" | "NO" | "before1932" |
   "父親" | "母親" | "兄弟" | "姊妹" | "子女" |
   "15年或以下" | "10-15年" | "15年以上" | "不適用" |
   "1-5枝" | "6-10枝" | "11-15枝" | "16-20枝" | "輸入香煙枝數" | "20枝以上" |
   "单位(年)" | "单位(cm)" | "单位(kg)" | "单位(枝)" |
   "简介" | "简介第1段落" |
   "简介第2段落" | "简介第2段落_上标" | "简介第2段落_尾" |
   "简介第3段落" | 
   "简介第4段落" | "简介第4段落_上标1" | "简介第4段落_尾1"  | "简介第4段落_上标2"  | "简介第4段落_尾2" | 
   "简介第5段落" | "简介第5段落_上标" |
    "简介第6段落" | "简介第7段落" | "Reference" |
   "第1部分" | "第一部分" | "个人基本资料" | "第2部分" | "第二部分" | "个人及家庭病史" | "第3部分" | "第三部分" | "个人吸烟史" |
   "上一题" | "下一题" |
   "重要事项" | "重要事项段落_1" | "重要事项段落_2" | "重要事项段落_3" | "重要事项段落_4" | "重要事项段落_5" | "重要事项段落_6" | "重要事项段落_7" |
   "重要事项段落_8" | "重要事项段落_9" | "重要事项段落_10" | "重要事项段落_11" | "重要事项段落_12" | "重要事项段落_13" |
   "重要事项段落_14" | "重要事项段落_15" | "重要事项段落_16" |
   "上一步" | "下一步" |
   "個人資料私隱政策聲明" | "隐私段落_1" | "隐私段落_2" | "隐私段落_3_前" | "隐私段落_3_邮箱" | "隐私段落_3_后" |
   "隐私段落_4_前" | "隐私段落_4_邮箱" | "隐私段落_4_后" | "隐私复选框_1" | "隐私复选框_2" | "提交" |
   "感谢参与" | "你患上肺癌的风险" | "较低" | "较高" | "下载评估结果" | "报告页脚信息1" | "报告页脚信息2" | "报告页脚信息3_前" | "报告页脚信息3_邮箱" | "报告页脚信息3_后" |
   "结果无风险段落_1" | "结果免费段落_1" | "结果免费段落_2_前" | "结果免费段落_2_中" | "结果免费段落_2_后" | "结果付费段落_1" | "结果付费段落_2" | "免费名额有限" |

   "报告段落_1" | "报告段落_2_免费" | "报告段落_3_低" |
   "报告段落_3_高" | "报告段落_3_前_免费"|"报告段落_3_电话_免费"|"报告段落_3_前_免费后" | "报告段落_3_后_免费" |"报告段落_3_中_免费"|
   "报告段落_3_中_付费" | "报告段落_3_后_付费" | "报告段落_4" |
   "参考编号" | "报告日期" | "单位(m)" | "单位(岁)" | "报告页脚信息4" | "请选择" | "立即进行肺癌风险评估"


   | "1结果上" | "1结果下"


   |"符合不免费"| "不包含" | "免费" | "不包含后" | "分类1" | "分类2" | "分类3" | "分类4"| "34类"

   |"不符合条件" | "不符合条件后" | "风险人士"| "风险人士后-冒号" | "风险人士后-上标" | "年龄介乎" | "小于15年" |"大于15年" | "没有吸烟习惯" | "风险可变" | "定期检查" | "上标12" |"上标45"| "定期检查后" |"健康饮食"| "上标1" | "上标4" | "参考资料" | "参考资料1" | "参考资料2" | "参考资料3" | "参考资料4" | "参考资料5" 

   |"免费配额用尽" | "名额已满" | "名额已满前" | "名额已满后"


export type Translate = {
   [translateKey in LanguageIndex]: string
}


export const translation: {
   [language in Language]: Translate

} = {
   tc: tcTranslate,
   sc: scTranslate,
   en: enTranslate,
}