import { logo, footer } from "../../config/pic";
import React, { PureComponent } from 'react';

type PageContentProps = {
    page: 1 | 2 | 3 | 4
    children: React.ReactNode
}
type PageContentState = {

}


export class PageContent extends PureComponent<PageContentProps, PageContentState> {
    constructor(props: PageContentProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        let top = (this.props.page - 1) * 297 + 45
        return <div className={"PageContent"} style={{
            top: `${top}mm`,
            position: "absolute",
            padding: "1cm",
            paddingTop: "0cm",
            width: "210mm",
        }}>
            {this.props.children}
        </div>

    }
}