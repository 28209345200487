import React, { PureComponent } from 'react';
import { Row, Col, Button } from 'antd';
import { Card, Spin } from 'antd';
import { EXEC, EXECString } from './function/exec';
import { Form } from './component/form';
import { Introduction } from './component/introduction';
import { BeforePost } from './component/beforePost';
import { Report } from './component/report';
import { Print } from './component/print/index';
import { hasVaildLanguage } from './function/lang';

type State = {
  nowFunction: "introduction" | "form" | "beforepost" | "report"
  form: { [str: string]: number }
  ReCAPTCHAKey?: string
}
class Main extends PureComponent<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      nowFunction: "introduction",
      // nowFunction: "beforepost",
      form: {}
    }
  }

  switch(tab: State["nowFunction"]) {
    this.setState({ nowFunction: tab })
  }

  render() {
    const now = this.state.nowFunction
    return (
      <div className="App"
        style={{
          overflow: "hidden",
          //  height: "100%",
          width: "100%", position: "relative"
        }}>
        <Background />
        <div className={"Background-cover"} style={{
          height: "100%",
          zIndex: 1,
          position: "relative",
          backgroundColor: "rgb(255 255 255 / 85%)",
        }}>
          <Introduction display={now === "introduction"} onClose={() => this.switch("form")} />
          <Form display={now === "form"} onFinished={(input) => {
            console.debug("debug", "Form", "onFinished", input)
            this.setState({ form: input })
            this.switch("beforepost")
          }} />
          <BeforePost
            display={now === "beforepost"}
            onCancel={() => this.switch("form")}
            onClose={(ReCAPTCHAKey) => {
              this.setState({
                ReCAPTCHAKey
              })
              this.switch("report")
            }}
          />
          {(() => {
            if (now === "report") {
              return <Report 
              ReCAPTCHAKey = {this.state.ReCAPTCHAKey}
              display={true}
                form={this.state.form}
                // form={{"HOW_OLD":2,"GENDER":0,"EDUCATION":1,"TALL":33,"WEIGHT":33,"IS_HK_RESIDENT":0,"HAD_COPD":0,"HAD_TPP":0,"HAD_CANCER":0,"HAD_FAMILY_CANCER":0,"FAMILY_WHO_HAD_CANCER":11,"SMOKE":0,"HOW_MUCH_SMOKE_PRE_DAY":3,"HOW_LONG_DID_YOU_SMOKE":33}}
                onClose={() => { }}
              />
            }
          })()}
        </div>

      </div >
    );

  }
}
class Background extends PureComponent<{}, {}> {
  render() {
    return <div className={"Background"} style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      opacity: 1,
      backgroundImage: `url(${"/bg.jpg"})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }} >
      {/* <div className={"Background-cover"} style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        opacity: 1,
        backgroundColor:"#ffffff85",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }} >
      </div> */}
    </div>
  }
}
type AppState = {
  nowFunction?: "Main" | "print"
}
class App extends PureComponent<{}, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }
  render() {
    switch (this.state.nowFunction) {
      case "Main":
        return <Main />
      case "print":
        return <Print />

      default:
        return "404"
    }

  }
  componentDidMount() {
    const pathnames = document.location.pathname.split("/")
    if (pathnames[1] === "print") {
      this.setState({
        "nowFunction": "print"
      })
    } else {
      this.setState({
        "nowFunction": 'Main'
      })
    }

    setInterval(() => {
      const height = document.getElementById("root")?.offsetHeight
      if (height) {
        EXECString(`document.getElementById('formIframe').style.height = '${height}px'`)
      }
    }, 200);



    if (!hasVaildLanguage()) {
      EXECString(`
      let language = document.location.pathname.split('/')[1];
      if(language === "tc" ||language === "sc" ||language === "en"){
      }else{
        if(document.location.pathname.endsWith("_en.html")){
          language = "en"
        }else if(document.location.pathname.endsWith("_tc.html")){
          language = "tc"
        }else if(document.location.pathname.endsWith("_sc.html")){
          language = "sc"
        }else{
          language = "en"
        }
      }

      let org = new URL(document.getElementById('formIframe').src).origin
      document.getElementById('formIframe').src = ""
      setTimeout(() => {
        document.getElementById('formIframe').src = org + '/#' + language
      }, 100);

      `);
    }
  }
}


export default App;
