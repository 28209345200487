import { LanguageIndex, Language, Translate } from "./index"


const translate: Translate = {
   "HOW_OLD": "你的出生年份是？",
   "HOW_OLD_IN_REPORT": "你的年齡是？",
   "YOUR_GENDER": "你的性別是?",
   "MAN": "男",
   "WOMAN": "女",
   "EDUCATION": "你的教育程度是?",
   "中學或以下": "中學或以下",
   "大學": "大學",
   "碩士或以上": "碩士或以上",
   "HOW_TALL": "你的身高是多少米?",
   "HOW_WEIGHT": "你的體重是多少公斤?",
   "IS_HK_RESIDENT": "你是否香港居民?",
   "HAD_COPD": "你是否曾經被診斷患上慢性阻塞性肺病、肺氣腫或慢性支氣管炎?",
   "HAD_TPP": "你是否曾經被診斷患上肺癆、肺塵埃沉着病(或間稱肺積塵病)或肺炎?",
   "HAD_CANCER": "你是否曾經被診斷患上癌症?",
   "HAD_FAMILY_CANCER": "你的親生父母、姊妹、兄弟或子女是否曾經被診斷患上肺癌?",
   "FAMILY_WHO_HAD_CANCER": "請選擇曾經被診斷患上肺癌的親人是? (可多項選擇)",
   "SMOKE": "你現在是否有吸食香煙的習慣?",
   "WAS_SMOKE": "你是否曾經有吸食香煙的習慣?",
   "TIME_QUIT_SMOKE": "你已戒煙多少年?",
   "HOW_MUCH_SMOKE_PRE_DAY": "根據你吸煙的習慣，平均每天會吸食多少枝香煙?",
   "HOW_LONG_DID_YOU_SMOKE": "你曾經或已經有多少年的吸煙習慣?",
   "单位(年)": "年",
   "单位(cm)": "厘米",
   "单位(m)": "米",
   "单位(岁)": "歲",
   "单位(kg)": "公斤",
   "单位(枝)": "枝",
   "YES": "是",
   "NO": "否",
   "before1932": "比1932早",
   "父親": "父親",
   "母親": "母親",
   "兄弟": "兄弟",
   "姊妹": "姊妹",
   "子女": "子女",
   "15年或以下": "15年或以下",
   "10-15年": "10-15年",
   "15年以上": "15年以上",
   "不適用": "不適用",
   "1-5枝": "1-5枝",
   "6-10枝": "6-10枝",
   "11-15枝": "11-15枝",
   "16-20枝": "16-20枝",
   "輸入香煙枝數": "20枝以上，請輸入香煙枝數",
   "20枝以上": "20枝以上",
   "简介": "簡介",
   "简介第1段落": "肺癌是香港頭號癌症殺手，在2019年，每四名因癌症死亡的病人裡便有一位是肺癌患者。¹",
   "简介第2段落": `吸煙是引致肺癌的一個主要原因。根據一個美國研究顯示，即使一些已戒煙25 年的人士仍然有高風險患上肺癌。²
   然而，肺癌卻不僅僅是一個吸煙人士才會患上的疾病，在東亞地區中，約有三分之一的肺癌患者是從不吸煙的人士³，他們或帶有其他的風險因素如有肺癌家族史`,
   "简介第2段落_上标": `4,5`,
   "简介第2段落_尾": `。因此，吸煙人士、已戒煙人士和非吸煙人士都同樣有患上肺癌的風險。`,
   "简介第3段落": `肺癌在發展初期通常沒有任何跡像或症狀，當病情進展時，症狀便會伴隨而來⁶，因此，肺癌通常在已發展至晚期時才被發現⁷，而預計治療結果通常較差。如果肺癌患者可以在第 I 期被診斷出來，他們的五年存活率介乎在 68-92%  ，而第 IV 期的五年存活率則低於 10%。⁸`,
   "简介第4段落": `肺癌篩查可以在肺癌還是在早期的時候把肺癌發現和診斷出來，讓一些高風險人士可及早發現患病並盡快得到適切的治療，從而提高患者的五年存活率。`,
   "简介第4段落_上标1": "9,10",
   "简介第4段落_尾1": "肺癌高風險人士可考慮進行肺癌篩查，以了解他們是否患有肺癌。篩查是指透過一些檢測，將仍未有症狀的患者找出來。¹¹低劑量電腦掃描(LDCT) 是迄今為止唯一被證明可有效篩查肺癌的臨床檢查方法。 幾項國際臨床研究已證明LDCT在肺癌高風險人士仍未有症狀時識別早期肺癌和降低肺癌死亡率方面的有效性。",
   "简介第4段落_上标2": "9,10",
   "简介第4段落_尾2": "",
   "简介第5段落": `然而，並非所有人士都需要進行LDCT肺癌篩查。 目前的證據表明，只有具肺癌風險因素的人士才需通過 LDCT 測試進行肺癌篩查。`,
   "简介第5段落_上标": `4,5,9,10`,
   "简介第6段落": `立即點選下面的評估按鈕，然後回答幾條簡單回題以了解你是否符合本免費低劑量電腦掃描肺癌篩查計劃的條件。符合資格的人士或有機會獲得免費醫療諮詢和 LDCT 篩查，免費名額有限，先到先得。`,
   "简介第7段落": `如果你對患上肺癌的風險因素或肺癌篩查有任何疑問，請諮詢你的家庭醫生。`,
   "Reference": "參考資料",
   "第1部分": "第1部分",
   "第一部分": "第一部分",
   "个人基本资料": "個人基本資料",
   "第2部分": "第2部分",
   "第二部分": "第二部分",
   "个人及家庭病史": "個人及家庭疾病史",
   "第3部分": "第3部分",
   "第三部分": "第三部分",
   "个人吸烟史": "個人吸煙史",
   "上一题": "上一題",
   "下一题": "下一題",
   "重要事项": "重要事項",
   "重要事项段落_1": "1.「#看透肺事」是一個附屬於「呼吸希望」的疾病教育計劃，而「呼吸希望」是由英國阿斯利康藥廠(香港) (下稱“AZHK”)及香港肺癌學會共同支持。",
   "重要事项段落_2": "2.此肺癌篩查計劃是一項由AZHK與亞洲癌症研究與教育聯盟“下稱ACARE”的合作項目。",
   "重要事项段落_3": "3.AZHK會資助低劑量電腦斷層掃描(下稱“LDCT”) 篩查和LDCT 前的醫療諮詢服務，而 ACARE 則會資助LDCT 後的醫療諮詢，並負責聘請合格的第三方提供所有醫療諮詢和 LDCT服務。",
   "重要事项段落_4": "4.所有參加者皆為自願參與肺癌風險評估、醫療諮詢及LDCT篩查服務。",
   "重要事项段落_5": "5.符合條件的參加者可免費接受LDCT篩查服務和LDCT前及後的醫療諮詢。 在進行LDCT篩查後，若醫護人員按參加者的狀況提出了任何後續建議，參加者可自行選擇是否接納，同時亦是由參加者自行承擔這些建議的費用和風險。",
   "重要事项段落_6": "6.由於LDCT篩查服務和LDCT前及後的醫療諮詢的免費名額有限，並非所有高風險參加者在完成此肺癌風險評估後得到免費的LDCT篩查服務和LDCT前及後的醫療諮詢。未能得到免費的LDCT篩查服務或醫療諮詢的高風險參加者可自行選擇是否進行LDCT篩查，同時亦是由參加者自行承擔進行LDCT篩查的費用和風險。",
   "重要事项段落_7": "7.符合條件的參加者需按指示自行聯絡ACARE安排免費的LDCT篩查服務和LDCT前的醫療諮詢。",
   "重要事项段落_8": "8.若符合條件的參加者因任何原因而未能或錯失了免費的LDCT篩查服務或LDCT前/後的醫療諮詢，ABOH、AZHK或ACARE將不會對參加者的任何損失承擔任何責任。",
   "重要事项段落_9": "9.如果參加者在 LDCT 前的醫療諮詢中被確定為不適合進行 LDCT篩查，則參加者將不會繼續進行免費 LDCT 篩查服務，亦不會得到任何未使用的服務的退款。",
   "重要事项段落_10": "10.參加者同意及允許ABOH、AZHK或ACARE在未來可刊登或公佈肺癌風險評估結果、LDCT篩查結果、病史和/或吸煙史的匯總數據。",
   "重要事项段落_11": "11.醫療諮詢或會由普通科醫生、家庭醫學專科醫生、呼吸系統科專科醫生或臨床腫瘤科專科醫生進行。",
   "重要事项段落_12": "12.此肺癌風險評估是根據數個國際研究起擬，並已由一群本地醫護人員審閱。",
   "重要事项段落_13": "13.此肺癌風險評估的計算是按參加者提供的答案來作評估，評估結果或未能完全代表個別參加者患上肺癌的風險。",
   "重要事项段落_14": "14.經評估為高風險的參加者並不代表現在或將來有機會患上肺癌，同樣地，經評估為低風險的參加者現在或將來或有機會患上肺癌。",
   "重要事项段落_15": "15.此肺癌風險評估的結果只作參考用，ABOH、AZHK或ACARE將不會對參加者因假陽性或假陰性的任何後果承擔任何責任，同時亦不對在 LDCT篩查服務或LDCT前/後的醫療諮詢期間提供的任何諮詢或建議負責。",
   "重要事项段落_16": "16.如參加者就LDCT篩查或/及肺癌風險評估有任何疑問，是由參加者自行負責在參與此肺癌篩查計劃前向醫生查詢。",
   
   "上一步": "上一步",
   "下一步": "下一步",
   "個人資料私隱政策聲明": "個人資料私隱政策聲明",
   "隐私段落_1": "1.參加者可自行決定是否提供資料以參加這個肺癌風險評估。",
   "隐私段落_2": "2.阿斯利康香港有限公司及其肺癌關注計劃「呼吸希望」會將參加者的肺癌風險評估結果轉移至亞洲癌症研究與教育聯盟作後續肺部低劑量電腦斷層掃描及醫療諮詢服務安排之用。",
   "隐私段落_3_前": "3.如參加者不欲將其肺癌風險評估結果轉移至亞洲癌症研究與教育聯盟，請以電郵通知「呼吸希望」，並註明「致呼吸希望私隱專員」（電郵地址：",
   "隐私段落_3_邮箱": "info@livingwithlungcancer.asia",
   "隐私段落_3_后": "）。「呼吸希望」會於接獲有關要求之日起40天內作出回覆。如「呼吸希望」未能於前述限期內處理有關要求，「呼吸希望」應於前述限期內通知參加者。然而，參加者將自動放棄後續低劑量電腦斷層掃描及醫療諮詢服務（如適用）。",
   "隐私段落_4_前": "4.上述用途均需符合阿斯利康香港有限公司私隱政策聲明，該聲明可參閱本網站底部的私隱政策聲明，或透過向本公司以電郵索取，並註明「致呼吸希望私隱專員」（電郵地址：",
   "隐私段落_4_邮箱": "info@livingwithlungcancer.asia ",
   "隐私段落_4_后": "）。",
   "隐私复选框_1": "本人已詳細閱讀上述個人資料私隱政策聲明、「呼吸希望」網站的私隱政策聲明及上述重要事項，並確認本人完全明白及同意有關內容。",
   "隐私复选框_2": "(如以上方格沒有打剔，「呼吸希望」未得到本人同意，有關本人肺癌風險將不會被評估。)",
   "提交": "提交",



   

   "感谢参与": "感謝你參與本評估!",
   "你患上肺癌的风险": "你患上肺癌的風險",
   "较低": "較低",
   "较高": "較高",
   "下载评估结果": "下載評估結果",
   "报告页脚信息1": "如果你對肺癌風險或肺癌篩查有任何疑問，請諮詢你的家庭醫生。",
   "报告页脚信息2": "如果你想了解更多關於肺癌的資訊，可瀏覽呼吸希望網站。",
   "报告页脚信息3_前": "如果你對此計劃有任何疑問，請發送電子郵件至",
   "报告页脚信息3_邮箱": " events@livingwithlungcancer.asia",
   "报告页脚信息3_后": "。",






   "结果无风险段落_1": "根據你的回答，你符合本免費低劑量電腦掃描肺癌篩查計劃的條件。",
   "结果免费段落_1": "根據你的回答，你患上肺癌的風險可能較其他人高，你可以考慮尋求醫療諮詢並進行低劑量電腦斷層掃描 (下稱“LDCT”) 篩查，以進一步了解你的肺部健康狀況。",
   "结果免费段落_2_前": "呼吸希望與亞洲癌症研究與教育聯盟（下稱“ACARE”）合作，為符合條件的參與者提供免費的醫療諮詢及LDCT篩查。請在本頁面下載你的評估結果，並請在七個工作日內，於辦公時間（星期一至五早上10:00 至下午6:00）內透過Whatsapp發送訊息至",
   "结果免费段落_2_中": " +852-64685836 ",
   "结果免费段落_2_后": "聯絡ACARE客戶服務人員，以安排免費的醫療諮詢和LDCT篩查。請在面見醫生時帶同此評估結果。免費名額有限，先到先得，預約一經確認後恕不更改。",
   "结果付费段落_1": "根據你的回答，你患上肺癌的風險可能較其他人高，你可以考慮尋求醫療諮詢並進行低劑量電腦斷層掃描 (下稱“LDCT”) 篩查，以進一步了解你的肺部健康狀況。",
   "结果付费段落_2": "如果你對肺癌風險或肺癌篩查有任何疑問，請諮詢你的家庭醫生。",

   "报告段落_1": "感謝你參與本評估，以下是你提供的答案。",
   "报告段落_2_免费": "呼吸希望與亞洲癌症研究與教育聯盟（下稱“ACARE”）合作，為符合條件的參加者提供免費的醫療諮詢及低劑量電腦掃描 (下稱“LDCT”) 篩查。",
   "报告段落_3_低": "根據你的回答，你患上肺癌的風險可能較低。",
   "报告段落_3_高": "根據你的回答，你符合本免費低劑量電腦掃描肺癌篩查計劃的條件。",
   
   "报告段落_3_中_免费": "請在七個工作日內，於辦公時間（星期一至五早上10:00 至下午 6:00）內透過Whatsapp 發送訊息至+852-64685836聯絡ACARE客戶服務人員，以安排免費的醫療諮詢和LDCT篩查",
   
   "报告段落_3_前_免费": "請在七個工作日內，於辦公時間（星期一至五早上10:00 至下午 6:00）內透過Whatsapp ",

   "报告段落_3_电话_免费": "發送訊息至 +852-64685836 ",

   "报告段落_3_前_免费后":"聯絡ACARE客戶服務人員，以安排免費的醫療諮詢和LDCT篩查",

   "报告段落_3_后_免费": "，請在面見醫生時帶同此評估結果，供醫生參考。",

   "免费名额有限" : "免費名額有限，先到先得，預約一經確認後，恕不更改。" ,



   "报告段落_3_中_付费": "你可以考慮尋求醫療諮詢並進行低劑量電腦斷層掃描篩查，",

   "报告段落_3_后_付费": "以進一步了解你的肺部健康狀況。",

   "报告段落_4": "如果你對患上肺癌的風險或肺癌篩查有任何進一步的疑問，請諮詢你的家庭醫生。",

   "参考编号": "參考編號",
   "报告日期": "報告日期",
   "请选择": "請選擇",
   "报告页脚信息4": "如需保存你的肺癌風險評估結果，請在離開或關閉本頁面前在此處下載",
   "立即进行肺癌风险评估": "立即進行評估",
   // 1
   "1结果上":"根據你的回答，你符合本","1结果下":"低劑量電腦掃描肺癌篩查計劃的條件。",

   //2.符合LDCT筛查标准，但不符合免费筛选计划的资格
   
   "符合不免费" : "根據你的回答，你可能需要進行肺癌篩查，你可以考慮尋求醫療諮詢並進行低劑量電腦掃描篩查，以進一步了解你的肺部健康狀況。",
   "不包含" : "不過，本",
   "免费":"免費",
   "不包含后": "低劑量電腦掃描肺癌篩查計劃並不涵蓋下列人士：",
   "分类1":"1.	 非香港居民",
   "分类2":"2.	 年齡少於50歲或超過80歲",
   "分类3":"3.  曾經被診斷患上肺癆、肺塵埃沉着病(或稱肺積塵病)或肺炎",
   "分类4":"4.  曾經被診斷患上癌症",
   "34类":"如果你是屬於上述第三或第四類人士，請與你的主診醫生討論是否需要進行肺癌篩查。",

   // 不符合LDCT筛查标准
   "不符合条件":"根據你的回答，你目前尚未符合本",
   "不符合条件后":"低劑量電腦掃描肺癌篩查計劃的條件。",
   "风险人士":"根據國際研究，透過低劑量電腦素描的肺癌篩查可考慮應用於有下列風險因素的人士",
   "风险人士后-冒号":"：",
   "风险人士后-上标":"1-3",
   "年龄介乎":"年齡介乎50-80歲",
   "小于15年":"現有吸煙習慣(或已戒煙不超過15年)並有20包年或以上的吸煙史",
   "大于15年":"現有吸煙習慣(或已戒煙不超過15年)並有少於20包年的吸煙史，則同時要考慮是否曾確診患上慢性阻塞性肺病、肺氣腫、慢性支氣管炎或有直系家族肺癌家族史",
   "没有吸烟习惯" : "從來沒有吸煙習慣(或已戒煙超過15年並有少於10包年的吸煙史)，同時有直系家族的肺癌家族史",
   "风险可变" : "而肺癌風險是可變的，當上述因素改變時，尤其當你仍在吸煙或有親人患上肺癌等，你可考慮再次進行肺癌風險評估。如有疑問，請與你的家庭醫生討論。",
   "定期检查" : "即便如此，你仍可透過改變可控的風險因素，降低肺癌發生的風險，例如：不要吸煙並避免吸入二手煙、長時間處於空氣污染的室内外環境、長期暴露在各種已知致癌物質的工作環境等。",
   "上标12":"1,2",
   "上标45":"4,5",
   "定期检查后":" 如果無法避免上述危險因素，可考慮定期進行身體健康檢查。",
   "健康饮食" : "另外，健康飲食，比如經常食用水果和蔬菜，以及進行適量運動，均會對健康有益，可能幫助降低罹患肺癌的風險。",
   "上标1":"1",
   "上标4":"4",
   "参考资料":"參考資料：",
   "参考资料1":"1.	Tammemagi MC, et al. Lancet Oncol 2022; 23:138-48.",
   "参考资料2":"2.	The US Preventive Services Task Force. JAMA 2021; 325(10):962-970.",
   "参考资料3":"3.	Yang P. Presentation at 2020 World Conference on Lung Cancer Singapore, 28-31 January 2021.",
   "参考资料4":"4.	American Cancer Society. Can lung cancer be prevented? https://www.cancer.org/cancer/lung-cancer/causes-risks-prevention/prevention.html. Accessed 18 June 2021. ",
   "参考资料5":"5.	National Cancer Institute. Lung Cancer Prevention (PDQ®)-patient version. https://www.cancer.gov/types/lung/patient/lung-prevention-pdq. Accessed 11 January 2022.",


   //符合LDCT筛查标准但免费配额用尽
   "免费配额用尽" : "根據你的回答，你可能需要進行肺癌篩查，你可以考慮尋求醫療諮詢並進行低劑量電腦斷層掃描篩查，以進一步了解你的肺部健康狀況。",
   "名额已满" : "不過，本免費低劑量電腦掃描肺癌篩查計劃的名額已滿，感謝你的支持。",
   "名额已满前" : "不過，本",
   "名额已满后" : "低劑量電腦掃描肺癌篩查計劃的名額已滿，感謝你的支持。"
}



export { translate }
