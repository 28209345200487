import React, { PureComponent } from 'react';
import { Button, Checkbox, Typography } from 'antd';
import { config as formConfig, report } from "../../config/forms/v1"
import { lang } from '../../function/lang';
import { logo, footer } from '../../config/pic';
import { getCurrentLanguage } from '../../function/lang';
import { checkBoxChecked } from '../../config/pic';

import "./print.less"
import { Document as PDFDocument, Page as PDFPage, pdfjs } from 'react-pdf';
import { PageContent } from './page';
// import * as PDF from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';


const { Title, Paragraph, Text, Link } = Typography;

type State = {
  type?: "1" | "2" | "3" | "4"
  code?: string
  part_1?: formData[]
  part_2?: formData[]
  part_3?: formData[]
  part_hide?: formData[]
  tableHeight?: number
  postTime?: Date
}

type formData = {
  tag: string
  part: number
  label: string
  value: number | string
  unit: string
}
interface PrintInterface {
}
export class Print extends PureComponent<PrintInterface, State> {
  tableDom: React.RefObject<HTMLTableElement>;
  BACKGROUND_PDF_URL = new URL(`https://cdn.jsdelivr.net/gh/4positives/public/form.livingwithlungcancer.asia/background.pdf`)
  refPDF: React.RefObject<PDFDocument>;
  constructor(props: PrintInterface) {
    super(props);
    this.state = {
    }
    this.refPDF = React.createRef()
    this.tableDom = React.createRef();
  }

  render() {
    let p1 = ""
    let p1_2 = ""
    let p1_3 = ""
    let p2 = ""
    let P3_1 = ""
    let P3_2 = ""
    let P3_2_1 = ""
    let P3_2_2 = ""
    let P3_3 = ""
    let p5 = ""
    let p5_3_a = ""
    let p5_3_b = ""
    let p5_a = ""
    let p5_b = ""
    let p5_c = ""
    let p5_d = ""
    let p5_e = ""
    let p5_f = ""
    let p5_g = ""
    let p5_h = ""
    let p5_h_1 = ""
    let p5_h_2 = ""
    let p5_i = ""
    let p5_i_1 = ""
    let p5_1 = ""
    let p5_2 = ""
    let p5_3 = ""
    let p5_4 = ""
    let p5_5 = ""

    let p4 = ""
    let p6 = ""
    let p7 = lang("报告段落_2_免费")
    let p8 = ""
    let p8_1 = ""
    let p8_2 = ""
    let p8_3 = ""
    let p8_4 = ""
    let p8_5 = ""

    // eslint-disable-next-line no-lone-blocks
    {
      (() => {
        switch (this.state.type) {
          case "1"://免费
            // P3_1 = lang("报告段落_3_高")
            //   p2 = lang("报告段落_2_免费")
            //   P3_2 = lang("报告段落_3_中_免费")
            //   P3_3 = lang("报告段落_3_后_免费")
            //   p4 = lang("报告段落_4")
            p1 = lang("1结果上")
            p1_2 = lang("免费")
            p1_3 = lang("1结果下")
            P3_1 = lang("报告段落_2_免费")
            P3_2 = lang("报告段落_3_前_免费")
            P3_2_1 = lang("报告段落_3_电话_免费")
            P3_2_2 = lang("报告段落_3_前_免费后")

            P3_3 = lang("报告段落_3_后_免费")
            p5 = lang("免费名额有限")
            p4 = lang("报告段落_4")
            p7 = ''
            break



          case "2"://付费
            P3_1 = lang("符合不免费")
            p5 = lang("不包含")
            p5_a = lang("免费")
            p5_b = lang("不包含后")
            p5_1 = lang("分类1")
            p5_2 = lang("分类2")
            p5_3 = lang("分类3")
            p5_4 = lang("分类4")
            p5_5 = lang("34类")
            p4 = lang("报告段落_4")
            p7 = ''
            break



          case "3"://低风险
            P3_1 = lang("不符合条件")
            P3_2 = lang("免费")
            P3_3 = lang("不符合条件后")
            p4 = lang("报告段落_4")
            p5 = lang("风险人士")
            p5_3_a = lang("风险人士后-上标")
            p5_3_b = lang("风险人士后-冒号")
            p5_c = lang("年龄介乎")
            p5_d = lang("小于15年")
            p5_e = lang("大于15年")
            p5_f = lang("没有吸烟习惯")
            p5_g = lang("风险可变")
            p5_h = lang("定期检查")
            p5_h_1 = lang("上标45")
            p5_h_2 = lang("定期检查后")
            p5_i = lang("健康饮食")
            p5_i_1 = lang("上标4")
            p7 = ''
            p8 = lang("参考资料")
            p8_1 = lang("参考资料1")
            p8_2 = lang("参考资料2")
            p8_3 = lang("参考资料3")
            p8_4 = lang("参考资料4")
            p8_5 = lang("参考资料5")


            break

          case "4"://符合LDCT筛查标准但免费配额用尽
            P3_1 = lang("免费配额用尽")
            p5 = lang("名额已满")
            p7 = ''
            p4 = lang("报告段落_4")
            break


          default:
        }
      })()
    }

    //1. Fulfil LDCT screening criteria   满足LDCT筛查标准
    //2. Fulfil LDCT screening criteria but not eligible for free screening program 符合LDCT筛查标准，但不符合免费筛选计划的资格
    //3. Not fulfil LDCT screening criteria 不符合LDCT筛查标准
    //4. Fulfil LDCT screening criteria but free quota exhausted 符合LDCT筛查标准但免费配额用尽







    let topClassNames = ["print-page",]
    if (this.state.type === "1" && getCurrentLanguage() === "en") {
      topClassNames.push("lh-fix")
    }
    let breakHeightId = 0
    let breakHeightId2 = 0
    {
      let height = this.state.tableHeight
      if (typeof (height) === 'number') {
        if (height > 400 && getCurrentLanguage() === "en" && this.state.type !== "4") {
          breakHeightId = 1
        }
      }
    }
    {
      let heightTwo = this.state.tableHeight
      if (typeof (heightTwo) === 'number') {
        console.log(heightTwo);

        if (this.state.type === "3" && getCurrentLanguage() !== "en") {
          breakHeightId2 = 1
        }
      }
    }

    //@ts-ignore
    const PDF_PAGE_1 = <PDFPage pageNumber={1} width={794} />
    //@ts-ignore
    const PDF_PAGE_2 = <PDFPage pageNumber={2} width={794} />
    //@ts-ignore
    const PDF_PAGE_3 = <PDFPage pageNumber={3} width={794} />
    //@ts-ignore
    const PDF_PAGE_4 = <PDFPage pageNumber={4} width={794} />
    //@ts-ignore
    const PDF_NODE = <PDFDocument
      ref={this.refPDF}
      renderMode={"svg"}
      file="/background.pdf"
      onLoadSuccess={this.onPDFLoaded.bind(this)}
      onLoadError={(event) => { console.log(event) }} >
      {PDF_PAGE_1}
      {PDF_PAGE_2}
      {PDF_PAGE_3}
      {this.state.type === "3" ? PDF_PAGE_4 : ""}
    </PDFDocument>


    const 首页尾部内容2 = <><Paragraph>
      {p5_g}
    </Paragraph>
      {
        (() => {
          return <>
            <Paragraph>
              {p5_h}<sup>{p5_h_1}</sup>{p5_h_2}
            </Paragraph>
          </>
        })()
      }
      <Paragraph>
        {p5_i}<sup>{p5_i_1}</sup>
      </Paragraph>
      <Paragraph className={"p5_1"}>
        {p5_1}
      </Paragraph>
      <Paragraph className={"p5_2"}>
        {p5_2}
      </Paragraph>
      <Paragraph className={"p5_3"}>
        {p5_3}
      </Paragraph>
      <Paragraph className={"p5_4"}>
        {p5_4}
      </Paragraph>
      <Paragraph className={"p5_5"}>
        {p5_5}
      </Paragraph>

      <Paragraph className={"p4"} style={{}}>
        {p4}
      </Paragraph>
    </>

    const 首页尾部内容3 = <>
      <Break if={this.state.type === '3' && getCurrentLanguage() === "en"}></Break>
      <Paragraph className={"p8"}>
        {p8}
      </Paragraph>
      <Paragraph className={"p8_12"}>
        <p>{p8_1}</p>
        <p>{p8_2}</p>
        <p>{p8_3}</p>
        <p>{p8_4}</p>
        <p>{p8_5}</p>
      </Paragraph>
    </>
    return <div className={topClassNames.join(" ")} id={"printPage"}
      style={{ width: 794, background: "yellow", overflow: "hidden", padding: 0 }}>
      <div style={{ position: "absolute" }}>{PDF_NODE}</div>
      <PageContent page={1}>

        {(() => {
          if (getCurrentLanguage() === "en") {
            return <Paragraph style={{ textAlign: "right" }}>
              {lang("参考编号")}{": "}{this.state.code}
              <br />
              {lang("报告日期")}{": "}{(this.state.postTime || new Date(0)).toLocaleDateString("en-GB")}
            </Paragraph>
          } else {
            return <Paragraph style={{ textAlign: "right" }}>
              {lang("参考编号")}{": "}{this.state.code}
              <br />
              {lang("报告日期")}{": "}
              {(this.state.postTime || new Date(0)).toLocaleDateString("de-DE", { year: "numeric" })}
              {"年"}
              {(this.state.postTime || new Date(0)).toLocaleDateString("de-DE", { month: "numeric" })}
              {"月"}
              {(this.state.postTime || new Date(0)).toLocaleDateString("de-DE", { day: "numeric" })}
              {"日"}
            </Paragraph>
          }
        })()}
        <Paragraph>
          {lang("报告段落_1")}
        </Paragraph>

        <Paragraph className={"lh-fix-tag"}>
          <table style={{ width: "100%" }} ref={this.tableDom}>
            <tbody>
              <tr style={{ backgroundColor: "#C5E0B3", fontWeight: 600 }}>
                <td height="20" width="422">
                  <span>{lang("第一部分")}</span>
                </td>
                <td width="248">
                  <span>{lang("个人基本资料")}</span>
                </td>
              </tr>
              {Array.from(this.state.part_1 || [], (item) => {
                return <tr className={`tag-${item.tag}`}>
                  <td height="20" width="422">
                    <span>{item.label}</span>
                  </td>
                  <td width="248">
                    <span>{item.value} {item.unit}</span>
                  </td>
                </tr>
              })}


              <tr style={{ backgroundColor: "#C5E0B3", fontWeight: 600 }}>
                <td height="20" width="422">
                  <span>{lang("第二部分")}</span>
                </td>
                <td width="248">
                  <span>{lang("个人及家庭病史")}</span>
                </td>
              </tr>
              {Array.from(this.state.part_2 || [], (item) => {
                return <tr className={`tag-${item.tag}`}>
                  <td height="20" width="422">
                    <span>{ }</span>
                    <span>{item.label}</span>
                  </td>
                  <td width="248">
                    <span>{item.value}</span>
                  </td>
                </tr>
              })}
              <tr style={{ backgroundColor: "#C5E0B3", fontWeight: 600 }}>
                <td height="20" width="422">
                  <span>{lang("第三部分")}</span>
                </td>
                <td width="248">
                  <span>{lang("个人吸烟史")}</span>
                </td>
              </tr>
              {Array.from(this.state.part_3 || [], (item) => {
                return <tr className={`tag-${item.tag}`}>
                  <td height="20" width="422">
                    <span>{item.label}</span>
                  </td>
                  <td width="248">
                    <span>{item.value} {item.unit}</span>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </Paragraph>
        <Break if={breakHeightId === 1
        } />
        <Paragraph className={"p1"}>
          {p1}<b>{p1_2}</b>{p1_3}
        </Paragraph>

        {(() => {
          if (p2) {
            return <Paragraph className={"P2"} style={{}}>
              {p2}
            </Paragraph>
          }
        })()}


        <Paragraph className={"P3"} style={{}}>
          {P3_1}
          <b>{P3_2}<a style={{ color: "#0000EE" }} href='http://wa.me/85264685836 '>{P3_2_1}</a>{P3_2_2}</b>
          {P3_3}

        </Paragraph>

        {(() => {
          if (p7 && !!!p2) {
            return <Paragraph className={"p7"}>
              {p7}
            </Paragraph>
          }
        })()}




        <Paragraph className={"p5"}>
          {p5}
          <sup>{p5_3_a}</sup>{p5_3_b}
          <b>{p5_a}</b>
          {p5_b}
        </Paragraph>

        {(() => {
          if (p5_c && p5_d && p5_e && p5_f) {
            return <>
              <Paragraph>
                <li>{p5_c}</li>
              </Paragraph>
              <Paragraph>
                <li> {p5_d}</li>
              </Paragraph>
              <Paragraph>
                <li> {p5_e}</li>
              </Paragraph>
              <Paragraph>
                <li> {p5_f}</li>
              </Paragraph>
            </>
          }
        })()}

        <Break if={breakHeightId2 === 1
        } />

        {this.state.type === "3" ? "" : 首页尾部内容2}
        {this.state.type === "3" ? "" : 首页尾部内容3}

      </PageContent>

      {this.state.type === "3" ? <PageContent page={2}>
        {首页尾部内容2}
        {首页尾部内容3}
      </PageContent> : ""}
      <PageContent page={this.state.type === "3" ? 3 : 2}>
        <Paragraph><Title level={4}>{lang("重要事项")}</Title></Paragraph>
        <Paragraph>{lang(`重要事项段落_${1}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${2}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${3}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${4}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${5}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${6}`)}</Paragraph>
        <Break if={this.state.type === "3" && getCurrentLanguage() === "en"} />
        <Break if={this.state.type === "2" && getCurrentLanguage() === "en"} />
        <Paragraph>{lang(`重要事项段落_${7}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${8}`)}</Paragraph>
        <Break if={this.state.type === "4" && getCurrentLanguage() === "en"} />
        <Paragraph>{lang(`重要事项段落_${9}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${10}`)}</Paragraph>
        <Break if={this.state.type === "1" && getCurrentLanguage() === "en"} />
        <Paragraph>{lang(`重要事项段落_${11}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${12}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${13}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${14}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${15}`)}</Paragraph>
        <Paragraph>{lang(`重要事项段落_${16}`)}</Paragraph>




      </PageContent>
      <PageContent page={this.state.type === "3" ? 4 : 3}>
        <Paragraph><Title level={4}>{lang("個人資料私隱政策聲明")}</Title></Paragraph>
        <Paragraph>{lang(`隐私段落_${1}`)}</Paragraph>
        <Paragraph>{lang(`隐私段落_${2}`)}</Paragraph>
        <Paragraph>
          {lang(`隐私段落_${3}_${"前"}`)}
          <Link>{lang(`隐私段落_${3}_${"邮箱"}`)}</Link>
          {lang(`隐私段落_${3}_${"后"}`)}
        </Paragraph>

        <Paragraph>
          {lang(`隐私段落_${4}_${"前"}`)}
          <Link>{lang(`隐私段落_${4}_${"邮箱"}`)}</Link>
          {lang(`隐私段落_${4}_${"后"}`)}
        </Paragraph>


        <Paragraph>
          <div style={{ width: "100%" }}>
            <div style={{ display: "inline-block", width: 24, verticalAlign: "top" }}>
              <img src={checkBoxChecked} style={{ height: 16 }} />
            </div>
            <div style={{ display: "inline-block", width: "calc(100% - 24px)" }}>
              <b>{lang("隐私复选框_1")}</b>

            </div>
          </div>

        </Paragraph>

        <Code />
      </PageContent>




      {/* <MarginIf display={getCurrentLanguage() !== "en"} >
          {"HK-6539 03/01/2022"}
        </MarginIf>
        <MarginIf display={getCurrentLanguage() === "en"} >
          {"HK-6539 03/01/2022"}
        </MarginIf> */}


      {/* </div> */}


    </div>

  }
  componentDidMount() {
    const type = document.location.pathname.split("/")[2]
    const code = document.location.pathname.split("/")[3]

    switch (type) {
      case "1":
        case "2":
        case "4":
      case "3":
        {
          this.setState({
            type,
            code
          })
          break;
        }
   
        // this.setState({
        //   type:"4",
        //   code
        // })
    }
    const configRaw = document.location.pathname.split("/")[4]
    const config = JSON.parse(decodeURIComponent(configRaw))
    const postTime = new Date(config["time"] || 0)
    this.setState({
      postTime
    })
    console.log("postTime", postTime)
    console.log("config", config)
    let getValue = (tag: string): number | false => {
      if (typeof (config[tag]) !== "undefined") {
        return config[tag]
      } else {
        return false
      }
    }
    let part_1: formData[] = []
    let part_2: formData[] = []
    let part_3: formData[] = []
    let part_hide: formData[] = []

    for (let configItem of formConfig.data) {
      const getResult = getValue(configItem.tag)
      console.log("getResult", configItem.tag, getResult)

      let value: number | string
      if (getResult === false) {
        continue
        // value = "/"
      } else {
        value = getResult
      }
      let HOW_MUCH_SMOKE_PRE_DAY_CUSTOM = false
      if ("HOW_MUCH_SMOKE_PRE_DAY" === configItem.tag) {
        let opValue = Number(value)
        console.log("running", "one", opValue)

        if (opValue >= 5) {
          value = lang("20枝以上")
          let nextValue = Number(getValue("HOW_MUCH_SMOKE_PRE_DAY_CUSTOM"))

          if (Number(nextValue) > 0) {
            HOW_MUCH_SMOKE_PRE_DAY_CUSTOM = true
            value = nextValue
          } else {
            value = lang("20枝以上")
          }
          // value = `123413-${ Number(value) }`

        }
        // if (opValue == 5) {
        //   value = lang("20枝以上")
        // }

      }



      let unit = ""

      switch (configItem.type) {
        case "NumberInput":
          unit = configItem.unit ? lang(configItem.unit) : ""
          value = (Number(value) + (configItem.min || 0) || 0)
          if (configItem.fix) {
            value = value.toFixed(configItem.fix)
          }
          break;
        case "checkbox":
          {
            let newvalue = ""
            let bvalues = (value).toString(2)
            let options = configItem.options
            var l = bvalues.length;    //获取要格式化数字的长度，如二进制1的话长度为1
            if (l < 5) {     //补全位数 0000，这里我要显示4位
              for (var i = 0; i < 5 - l; i++) {
                bvalues = "0" + bvalues;
                //不够的就在前面补0
              }
            }
            let bValues = bvalues.split('')
            // eslint-disable-next-line no-lone-blocks
            {
              // eslint-disable-next-line array-callback-return
              Array.from(bValues, (num, id) => {
                if (num === "1") {
                  if (typeof (options[id]) === "object") {
                    let option = options[id]
                    if (typeof (option.label) !== "number") {
                      if (newvalue.length === 0) {
                        newvalue = lang(option.label)
                      } else {
                        newvalue += "," + lang(option.label)
                      }
                    }
                  }
                }
              })
            }

            if (newvalue === "") {
              newvalue = "/"
            }

            value = newvalue
          }
          unit = ""
          break;
        case "radio":
        case "select":
          unit = ""
          for (let option of configItem.options) {

            if (option.value === value) {
              if (typeof (option.label) === "string") {
                value = lang(option.label)
              } else {
                //获取当前年份
                let now = new Date()
                let year = now.getFullYear()
                console.log("year", year)
                if ("HOW_OLD" === configItem.tag) {
                  let yearsOld = Math.abs(Number(option.label) - year)
                  console.log("yearsOld", yearsOld)
                  value = String(yearsOld)
                  unit = lang("单位(岁)")
                } else {
                  value = Math.abs(Number(option.label) - year)
                }
              }
            }

          }

          break;

        default:
          break;
      }

      if (HOW_MUCH_SMOKE_PRE_DAY_CUSTOM) {
        value += ` ${lang("单位(枝)")}`
      }


      let data: formData = {
        tag: configItem.tag,
        part: configItem.part,
        label: Array.from((configItem.alias ? configItem.alias : configItem.title), titleKey => lang(titleKey)).join(" "),
        value: value,
        unit: unit
      }
      if (value === "/" || configItem.displayInReport === false) {
        part_hide.push(data)
        continue
      }

      switch (configItem.part) {
        case 0:
          part_1.push(data)
          break;
        case 1:
          part_2.push(data)
          break;
        case 2:
          part_3.push(data)
          break;

        default:
          break;
      }
    }

    console.log("part_", part_1, part_2, part_3)
    this.setState({
      part_1, part_2, part_3, part_hide
    })
    let year = new Date().toLocaleDateString("de-DE", { year: "numeric" })
    let month = new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
    let day = new Date().toLocaleDateString("de-DE", { day: "numeric" })
    let time = year + month + day
    document.title = `SeeTheFacts - ${code} -    ${time}`
  }
  componentDidUpdate() {
    if (typeof (this.state.tableHeight) === "undefined") {
      let tableDom = this.tableDom.current
      if (null !== tableDom) {
        let height = tableDom.offsetHeight
        this.setState({
          tableHeight: height
        })
        console.log("tableHeight", height)
      }
    }
  }
  onPDFLoaded() {
    const pics = this.state.type === "3" ? 8 : 6
    const timer = setInterval(() => {
      if (document.getElementsByClassName("react-pdf__message--loading").length === 0) {
        if (document.getElementsByTagName("svg:image").length === pics) {
          clearInterval(timer)
          setTimeout(() => {
            window.print()
          }, 500)
        }
      }
    }, 100)

  }
}




interface ifProps {
  height?: number
  if?: boolean
  children?: React.ReactNode
}
// export class MarginIf extends PureComponent<ifProps, {}> {
//   constructor(props: ifProps) {
//     super(props);
//     this.state = {
//     }
//   }

//   render() {
//     const marginBottom = `${this.props.height || 1}cm`
//     const display = this.props.display ? undefined : "none"
//     if (this.props.children) {
//       return <div className={"print-MarginIf"} style={{
//         display, marginBottom
//       }} >
//         {this.props.children}
//       </div>
//     }
//     return <div className={"print-MarginIf"} style={{
//       display, marginBottom, color: "transparent"
//     }} >
//       {"-"}
//     </div>

//   }
// }
// export class FootrtIf extends PureComponent<ifProps, {}> {
//   constructor(props: ifProps) {
//     super(props);
//     this.state = {
//     }
//   }

//   render() {
//     return <div className={"print-FootrtIf"} style={{
//       display: this.props.display ? undefined : "none",
//       height: "3cm", width: "100%", textAlign: "center"
//     }}>
//       <img src={footer} style={{
//         height: "100%",
//         position: "relative",
//         left: "-1cm"
//       }} />
//     </div>

//   }
// }
class Break extends PureComponent<ifProps, {}> {
  constructor(props: ifProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    let display = "block"
    if (this.props.hasOwnProperty("if")) {
      if (!!!this.props.if) {
        display = "none"
      }
    }
    return <div className={"print-Break"} style={{
      display,
      // backgroundColor: "aqua",
      pageBreakBefore: "left",
    }}>

      <Gap>
        {/* BRK */}
      </Gap>
    </div>

  }
}
class Gap extends PureComponent<ifProps, {}> {
  constructor(props: ifProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    let display = "block"
    if (this.props.hasOwnProperty("if")) {
      if (!!!this.props.if) {
        display = "none"
      }
    }
    return <div className={"print-Break"} style={{
      display,
      height: `${3 + 1}cm`
    }}>
      {/* {this.props.children}_-_GAP */}
    </div>

  }
}

class Code extends PureComponent<ifProps, {}> {
  constructor(props: ifProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    let display = "block"
    if (this.props.hasOwnProperty("if")) {
      if (!!!this.props.if) {
        display = "none"
      }
    }
    return <div className={"print-code"} style={{
      display,
      height: `${3 + 1}cm`,
      marginTop: "5cm",
      // position: "absolute",
      // bottom: 0
    }}>
      {"HK-6617 07/02/2022"}
    </div>

  }
}
