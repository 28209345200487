
export function EXEC(cmd: () => void) {
   // EXECString(`(${cmd.toString()})()`)

}

export function EXECString(cmd: string) {
   const text = JSON.stringify({
      from: 'auth',
      event: 'exec',
      code: cmd
   })

   if (localStorage.dev === "true") {
      console.warn("posting", text)
   }

   window.parent.postMessage(text, '*')
}