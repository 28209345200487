import { Form, Reports, CodeTreeConfig } from "./formTypes"

export const config: Form = {
    ver: 1,
    data: [
        {
            part: 0,
            "tag": "HOW_OLD",
            "type": "select",
            "title": [
                "HOW_OLD"
            ],
            "alias": ["HOW_OLD_IN_REPORT"],
            "unit": "单位(年)",
            "defaultOptionValue": 69,
            "options": [
                { "value": 0, "label": "before1932" },
                { "value": 1, "label": 1932 },//89岁
                { "value": 2, "label": 1933 }, 
                { "value": 3, "label": 1934 },
                { "value": 4, "label": 1935 },
                { "value": 5, "label": 1936 },
                { "value": 6, "label": 1937 },
                { "value": 7, "label": 1938 },
                { "value": 8, "label": 1939 },
                { "value": 9, "label": 1940 },
                { "value": 10, "label": 1941 },
                { "value": 11, "label": 1942 },//80岁
                { "value": 12, "label": 1943 },
                { "value": 13, "label": 1944 },
                { "value": 14, "label": 1945 },
                { "value": 15, "label": 1946 },
                { "value": 16, "label": 1947 },
                { "value": 17, "label": 1948 },
                { "value": 18, "label": 1949 },
                { "value": 19, "label": 1950 },
                { "value": 20, "label": 1951 },
                { "value": 21, "label": 1952 },
                { "value": 22, "label": 1953 },
                { "value": 23, "label": 1954 },
                { "value": 24, "label": 1955 },
                { "value": 25, "label": 1956 },
                { "value": 26, "label": 1957 },
                { "value": 27, "label": 1958 },
                { "value": 28, "label": 1959 },
                { "value": 29, "label": 1960 },
                { "value": 30, "label": 1961 },
                { "value": 31, "label": 1962 },
                { "value": 32, "label": 1963 },
                { "value": 33, "label": 1964 },
                { "value": 34, "label": 1965 },
                { "value": 35, "label": 1966 },
                { "value": 36, "label": 1967 },
                { "value": 37, "label": 1968 },
                { "value": 38, "label": 1969 },
                { "value": 39, "label": 1970 },
                { "value": 40, "label": 1971 },
                { "value": 41, "label": 1972 },//50岁
                { "value": 42, "label": 1973 },
                { "value": 43, "label": 1974 },
                { "value": 44, "label": 1975 },
                { "value": 45, "label": 1976 },
                { "value": 46, "label": 1977 },
                { "value": 47, "label": 1978 },
                { "value": 48, "label": 1979 },
                { "value": 49, "label": 1980 },
                { "value": 50, "label": 1981 },
                { "value": 51, "label": 1982 },
                { "value": 52, "label": 1983 },
                { "value": 53, "label": 1984 },
                { "value": 54, "label": 1985 },
                { "value": 55, "label": 1986 },
                { "value": 56, "label": 1987 },
                { "value": 57, "label": 1988 },
                { "value": 58, "label": 1989 },
                { "value": 59, "label": 1990 },
                { "value": 60, "label": 1991 },
                { "value": 61, "label": 1992 },
                { "value": 62, "label": 1993 },
                { "value": 63, "label": 1994 },
                { "value": 64, "label": 1995 },
                { "value": 65, "label": 1996 },
                { "value": 66, "label": 1997 },
                { "value": 67, "label": 1998 },
                { "value": 68, "label": 1999 },
                { "value": 69, "label": 2000 },
                { "value": 70, "label": 2001 },
                { "value": 71, "label": 2002 },
                { "value": 72, "label": 2003 },
                { "value": 73, "label": 2004 },
                { "value": 74, "label": 2005 },
                { "value": 75, "label": 2006 },
                { "value": 76, "label": 2007 },
                { "value": 77, "label": 2008 },
                { "value": 78, "label": 2009 },
                { "value": 79, "label": 2010 },
                { "value": 80, "label": 2011 },
                { "value": 81, "label": 2012 },
                { "value": 82, "label": 2013 },
                { "value": 83, "label": 2014 },
                { "value": 84, "label": 2015 },
                { "value": 85, "label": 2016 },
                { "value": 86, "label": 2017 },
                { "value": 87, "label": 2018 },
                { "value": 88, "label": 2019 },
                { "value": 89, "label": 2020 },//2岁
                { "value": 90, "label": 2021 },//1岁
                { "value": 91, "label": 2022 },//0岁
            ]
        },
        {
            part: 0,
            "tag": "GENDER",
            "type": "select",
            "title": [
                "YOUR_GENDER"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "MAN"
                },
                {
                    "value": 1,
                    "label": "WOMAN"
                }
            ]
        },
        {
            part: 0,
            "tag": "EDUCATION",
            "type": "select",
            "title": [
                "EDUCATION"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "中學或以下"
                },
                {
                    "value": 1,
                    "label": "大學"
                },
                {
                    "value": 2,
                    "label": "碩士或以上"
                }
            ]
        },
        {
            part: 0,
            "tag": "TALL",
            "type": "NumberInput",
            max: 2.5,
            min: 0.1,
            fix: 2,
            "unit": "单位(m)",
            "title": [
                "HOW_TALL"
            ]
        },
        {
            part: 0,
            "tag": "WEIGHT",
            "type": "NumberInput",
            max: 119,
            min: 20,
            "unit": "单位(kg)",
            "title": [
                "HOW_WEIGHT"
            ]
        },
        {
            part: 0,
            "tag": "IS_HK_RESIDENT",
            "type": "radio",
            "title": [
                "IS_HK_RESIDENT"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES"
                },
                {
                    "value": 1,
                    "label": "NO"
                }
            ]
        },
        {
            part: 1,
            "tag": "HAD_COPD",
            "type": "radio",
            "title": [
                "HAD_COPD"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES"
                },
                {
                    "value": 1,
                    "label": "NO"
                }
            ]
        },
        {
            part: 1,
            "tag": "HAD_TPP",
            "type": "radio",
            "title": [
                "HAD_TPP"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES"
                },
                {
                    "value": 1,
                    "label": "NO"
                }
            ]
        },
        {
            part: 1,
            "tag": "HAD_CANCER",
            "type": "radio",
            "title": [
                "HAD_CANCER"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES"
                },
                {
                    "value": 1,
                    "label": "NO"
                }
            ]
        },
        {
            part: 1,
            "tag": "HAD_FAMILY_CANCER",
            "type": "radio",
            // "displayInReport" : false,
            "title": [
                "HAD_FAMILY_CANCER"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES"
                },
                {
                    "value": 1,
                    "label": "NO",
                    "goto": "SMOKE"
                }
            ]
        },
        {
            part: 1,
            "tag": "FAMILY_WHO_HAD_CANCER",
            "type": "checkbox",
            "title": [
                "FAMILY_WHO_HAD_CANCER"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "父親"
                },
                {
                    "value": 1,
                    "label": "母親"
                },
                {
                    "value": 2,
                    "label": "兄弟"
                },
                {
                    "value": 3,
                    "label": "姊妹"
                },
                {
                    "value": 4,
                    "label": "子女"
                }
            ]
        },
        {
            part: 2,
            "tag": "SMOKE",
            "type": "radio",
            "title": [
                "SMOKE"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES",
                    "goto": "HOW_MUCH_SMOKE_PRE_DAY"
                },
                {
                    "value": 1,
                    "label": "NO"
                }
            ]
        },
        {
            part: 2,
            "tag": "WAS_SMOKE",
            "type": "radio", 
            // "displayInReport" : false,
            "title": [
                "WAS_SMOKE"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "YES"
                },
                {
                    "value": 1,
                    "label": "NO",
                    "goto": "END"
                }
            ]
        },
        {
            part: 2,
            "tag": "TIME_QUIT_SMOKE",
            "type": "select",
            // "displayInReport" : false,
            "title": [
                "TIME_QUIT_SMOKE"
            ],
            "options": [
                {
                    "value": 0,
                    "label": "15年或以下"
                },
                {
                    "value": 1,
                    "label": "15年以上"
                }
            ]
        },
        {
            part: 2,
            "tag": "HOW_MUCH_SMOKE_PRE_DAY",
            // "displayInReport" : false,
            "type": "select",
            "title": [
                "HOW_MUCH_SMOKE_PRE_DAY"
            ],
            "options": [
                {
                    "value": 1,//1=0.5
                    "label": "1-5枝"
                },
                {
                    "value": 2,
                    "label": "6-10枝"
                },
                {
                    "value": 3,
                    "label": "11-15枝"
                },
                {
                    "value": 4,
                    "label": "16-20枝"
                },
                {
                    "value": 5,
                    "label": "輸入香煙枝數",
                    "custom": "NumberInput",
                    "unit": "单位(枝)"
                }
                // {
                //     "value": 5,
                //     "label": "不適用"
                // },
            ]
        },
        {
            part: 2,
            "tag": "HOW_LONG_DID_YOU_SMOKE",
            // "displayInReport" : false,
            "type": "NumberInput",
            unit : "单位(年)",
            max: 80,
            min: 0,
            "title": [
                "HOW_LONG_DID_YOU_SMOKE"
            ]
        }
    ]
}
export const report: Reports = {
    ver: 1,
    data: [

        // {
        //     "tag": "HOW_OLD_PRE",
        //     "inputTag": "HOW_OLD",
        //     "options": [
        //         //1971年（40）为50岁，小于50岁才直接出结果
        //         {
        //             "value": 0,
        //             "goto": "SMOKE"
        //         },
        //         {
        //             "value": 41,
        //             "goto": "RESULT_3"
        //         },

        //     ]
        // },
        {
            "tag": "SMOKE",
            "inputTag": "SMOKE",
            "options": [
                {
                    "value": 0,
                    "goto": "HOW_MUCH_SMOKE_PRE_DAY3"
                },
                {
                    "value": 1,
                    "goto": "WAS_SMOKE"
                }
            ]
        },
        { //3.1=>no=>
            "tag": "WAS_SMOKE",
            "inputTag": "WAS_SMOKE",
            "options": [
                {
                    "value": 0,
                    "goto": "TIME_QUIT_SMOKE"
                },
                {
                    "value": 1,
                    "goto": "HAD_FAMILY_CANCER1"
                }
            ]
        },
        { //3.1=>no=>3.2=>no=>
            "tag": "HAD_FAMILY_CANCER1",
            "inputTag": "HAD_FAMILY_CANCER",
            "options": [
                {
                    "value": 0,
                    "goto": "IS_HK_RESIDENT"
                },
                {
                    "value": 1,
                    "goto": "RESULT_3"
                }
            ]
        },
        {
            "tag": "HAD_FAMILY_CANCER",
            "inputTag": "HAD_FAMILY_CANCER",
            "options": [
                {
                    "value": 0,
                    "goto": "HAD_COPD"
                },
                {
                    "value": 1,
                    "goto": "RESULT_3"
                }
            ]
        },
        {
            //3.1=>yes=>Q3.4&3.5=>选9-16
            "tag": "HAD_FAMILY_CANCER2",
            "inputTag": "HAD_FAMILY_CANCER",
            "options": [
                {
                    "value": 0,
                    "goto": "IS_HK_RESIDENT"
                },
                {
                    "value": 1,
                    "goto": "RESULT_3"
                }
            ]
        },
        // {
        //     //3.1=>yes=>Q3.4&3.5=>2.1(2)=>no=>选17-19
        //     "tag": "HAD_FAMILY_CANCER3",
        //     "inputTag": "HAD_FAMILY_CANCER",
        //     "options": [
        //         {
        //             "value": 0,
        //             "goto": "IS_HK_RESIDENT"
        //         },
        //         {
        //             "value": 1,
        //             "goto": "RESULT_3"
        //         }
        //     ]
        // },
        {
            "tag": "HAD_COPD",
            "inputTag": "HAD_COPD",
            "options": [
                {
                    "value": 0,
                    "goto": "IS_HK_RESIDENT"
                },
                {
                    "value": 1,
                    "goto": "RESULT_3"
                }
            ]
        },
        {
            //3.1=>yes=>Q3.4&3.5=>2.1(2)
            "tag": "HAD_COPD2",
            "inputTag": "HAD_COPD",
            "options": [
                {
                    "value": 0,
                    "goto": "IS_HK_RESIDENT"
                },
                {
                    "value": 1,
                    "goto": "HAD_FAMILY_CANCER2"
                }
            ]
        },
        { //3.1=>no=>3.2=>yes=>
            "tag": "TIME_QUIT_SMOKE",
            "inputTag": "TIME_QUIT_SMOKE",
            "options": [
                {
                    "value": 0,
                    "goto": "HOW_MUCH_SMOKE_PRE_DAY"
                },
                {
                    "value": 1,
                    "goto": "HOW_MUCH_SMOKE_PRE_DAY2"
                }
            ]
        },
        {
            "tag": "HOW_MUCH_SMOKE_PRE_DAY",
            "inputTag": "HOW_MUCH_SMOKE_PRE_DAY",
            "timesTag": "HOW_LONG_DID_YOU_SMOKE",
            "multiple": 0.25,//0.5=>0.25 整体缩小一倍
            "options": [
                {
                    "value": 0,
                    "goto": "HAD_FAMILY_CANCER"
                },
                {
                    "value": 10,
                    "goto": "HAD_FAMILY_CANCER"
                },
                {
                    "value": 20,
                    "goto": "IS_HK_RESIDENT"
                }
            ]
        },
        {
            "tag": "HOW_MUCH_SMOKE_PRE_DAY2",
            "inputTag": "HOW_MUCH_SMOKE_PRE_DAY",
            "timesTag": "HOW_LONG_DID_YOU_SMOKE",
            "multiple": 0.25,
            "options": [
                {
                    "value": 0,
                    "goto": "HAD_FAMILY_CANCER2"
                },
                {
                    "value": 10,
                    "goto": "RESULT_3"
                },
                {
                    "value": 20,
                    "goto": "RESULT_3"
                }
            ]
        },
        {   
            //3.1=>yes=>
            "tag": "HOW_MUCH_SMOKE_PRE_DAY3",
            "inputTag": "HOW_MUCH_SMOKE_PRE_DAY",
            "timesTag": "HOW_LONG_DID_YOU_SMOKE",
            "multiple": 0.25,
            "options": [
                {
                    "value": 0,
                    "goto": "RESULT_2"
                },
                {
                    "value": 1,
                    "goto": "HAD_FAMILY_CANCER"
                },
                {
                    "value": 9,
                    "goto": "HAD_FAMILY_CANCER2"
                },
                {
                    "value": 17,
                    "goto": "HAD_COPD2"
                },
                {
                    "value": 20,
                    "goto": "IS_HK_RESIDENT"
                }
            ]
        },
        {
            "tag": "IS_HK_RESIDENT",//是否香港居民
            "inputTag": "IS_HK_RESIDENT",
            "options": [
                {
                    "value": 0,
                    "goto": "HAD_TPP"
                },
                {
                    "value": 1,
                    "goto": "RESULT_2"
                }
            ]
        },
        {
            "tag": "HAD_TPP",//是否曾經被診斷患上肺癆、肺塵埃沉着病(或稱肺積塵病)或肺炎?
            "inputTag": "HAD_TPP",
            "options": [
                {
                    "value": 0,
                    "goto": "RESULT_2"
                },
                {
                    "value": 1,
                    "goto": "HAD_CANCER"
                }
            ]
        },
        {
            "tag": "HAD_CANCER",//是否曾經被診斷患上癌症?
            "inputTag": "HAD_CANCER",
            "options": [
                {
                    "value": 0,
                    "goto": "RESULT_2"
                },
                {
                    "value": 1,
                    "goto": "HOW_OLD_FINAL"
                }
            ]
        },
        {
            "tag": "HOW_OLD_FINAL", //年龄
            "inputTag": "HOW_OLD",
            "options": [
                {
                    "value": 0,
                    "goto": "RESULT_2"
                },
                {
                    "value": 12,
                    "goto": "RESULT_1"
                },
                {
                    "value": 42,
                    "goto": "RESULT_2"
                }
            ]
        },
    ]
}
export const codeTree: CodeTreeConfig = {
    ver: 1,
    data: [
        {
            tag: "HOW_OLD",
            maxNumber: 91,
            method: "Decimal"
        },
        {
            tag: "GENDER",
            maxNumber: 2,
            method: "Binary"
        },
        {
            tag: "EDUCATION",
            maxNumber: 3,
            method: "Decimal"
        },
        {
            tag: "TALL",
            /**100到200厘米 */
            maxNumber: 2,
            times: 100,
            method: "Decimal"
        },
        {
            tag: "WEIGHT",
            maxNumber: 100,
            method: "Decimal"
        },
        {
            tag: "IS_HK_RESIDENT",
            maxNumber: 2,
            method: "Binary",
            split: true
        },
        {
            tag: "HAD_COPD",
            maxNumber: 2,
            method: "Binary"
        },

        {
            tag: "HAD_TPP",
            maxNumber: 2,
            method: "Binary"
        },

        {
            tag: "HAD_CANCER",
            maxNumber: 2,
            method: "Binary"
        },
        {
            tag: "HAD_FAMILY_CANCER",
            maxNumber: 2,
            method: "Binary"
        },
        {
            tag: "FAMILY_WHO_HAD_CANCER",
            maxNumber: 100,
            method: "Decimal"
        },
        {
            tag: "SMOKE",
            maxNumber: 2,
            method: "Binary"
        },
        {
            tag: "WAS_SMOKE",
            maxNumber: 2,
            method: "Binary"
        },
        {
            tag: "TIME_QUIT_SMOKE",
            maxNumber: 4,
            method: "Decimal"
        },
        {
            tag: "TIME_QUIT_SMOKE",
            maxNumber: 4,
            method: "Decimal"
        },
        {
            tag: "HOW_MUCH_SMOKE_PRE_DAY",
            maxNumber: 5,
            method: "Decimal"
        },
        {
            tag: "HOW_LONG_DID_YOU_SMOKE",
            maxNumber: 100,
            method: "Decimal"
        },


    ]
}
