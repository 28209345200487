import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { Typography } from 'antd';
import { lang } from '../function/lang';

const { Title, Paragraph, Text } = Typography;

type State = {
}

interface IntroductionInterface {
  display: boolean
  onClose: () => void
}


export class Introduction extends PureComponent<IntroductionInterface, State> {
  constructor(props: IntroductionInterface) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div style={{ display: this.props.display ? undefined : "none", padding: 28, height: "100%", overflow: "auto" }}>
        <div className={"Introduction-title"} style={{ textAlign: "center" }}>
          <Paragraph>
            <Title level={3}>{lang("简介")}</Title>
          </Paragraph>
        </div>
        <div className={"Introduction-content"} style={{}}>
          <Typography style={{ fontSize: "1.3em" }}>
            <Paragraph children={lang("简介第1段落")} />
            <Paragraph  >
              {lang("简介第2段落")}
              <sup>{lang("简介第2段落_上标")}</sup>
              {lang("简介第2段落_尾")}
            </Paragraph>
            <Paragraph children={lang("简介第3段落")} />
            <Paragraph  >
              {lang("简介第4段落")}
              <sup>{lang("简介第4段落_上标1")}</sup>
              {lang("简介第4段落_尾1")}
              <sup>{lang("简介第4段落_上标2")}</sup>
              {lang("简介第4段落_尾2")}
            </Paragraph>
            <Paragraph  >
              {lang("简介第5段落")}
              <sup>{lang("简介第5段落_上标")}</sup>
            </Paragraph>
            <Paragraph children={lang("简介第6段落")} />
            <Paragraph children={lang("简介第7段落")} />
          </Typography>
        </div>
        <div className={"Introduction-button"} style={{ textAlign: "center" }}>
          <Paragraph style={{ fontSize: "1.3em" }}>
            <Button
              size={"large"}
              onClick={this.props.onClose} 
              // onClick={this.validate}


              style={{
                // height: 37,
                // fontSize: 26,
                borderRadius: 10
              }}>
              {lang("立即进行肺癌风险评估")}
            </Button>
          </Paragraph>

        </div>
        <div className={"Introduction-reference"} style={{}}>
          <Paragraph>
            <Title level={5}>{lang("Reference")}:</Title>
            1. Hong Kong Cancer Registry. Available at: https://www3.ha.org.hk/cancereg/topten.html.  Accessed: 12 Oct 2021.
            <br />
            2. Tindle HA, et al. J Natl Cancer Inst 2018; 110(11): 1201-07.
            <br />
            3. Zhou F and Zhou C. Transl Lung Cancer Res 2018; 7(4): 450-463.
            <br />
            4.Yang P. J Thorac Oncol. 2021;16(3S):S58-S62.
            <br />
            5. Yang P. Presentation at 2020 World Conference on Lung Cancer Singapore, 28-31 January 2021.
            <br />
            6. National Health Service. Available at: https://www.nhs.uk/conditions/lung-cancer/symptoms/. Accessed: 8 Sep 2021.
            <br />
            7. Lung Cancer Europe. Available at: https://www.lungcancereurope.eu/wp-content/uploads/2019/11/WEB-VERSION-IV-LuCE-Report.pdf.pdf. Accessed: 8 Sep 2021.
            <br />
            8. The Lung Ambition Alliance. Lung cancer screening: the cost of inaction. July 2021. Available at: https://www.healthpolicypartnership.com//app/uploads/Lung-cancer-screening-the-cost-of-inaction.pdf. Accessed: 12 October 2021.
            <br />
            9. National Lung Screening Trial Research Team. N Engl J Med. 2011;365(5):395-409.
            <br />
            10. de Koning HJ, et al. N Engl J Med. 2020;382(6):503-513.
            <br />
            11. American Cancer Society. Available at: https://www.cancer.org/cancer/lung-cancer/detection-diagnosis-staging/detection.html. Accessed: 8 Sep 2021.
          </Paragraph>
        </div>


      </div>
    );

  }
}

